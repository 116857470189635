import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearchMinus,
	faCheck,
	faTimes,
	faCalendarAlt,
	faInfoCircle,
	faExclamationTriangle,
	faExclamationCircle,
	faClipboard,
	faChartPie,
	faBook,
	faFilter,
	faCogs,
	faNotEqual,
	faBookOpen,
	faAngleDoubleRight,
	faAngleRight,
	faAngleDown,
	faSortUp,
	faSortDown,
	faArrowAltCircleRight,
	faBug,
	faSyncAlt,
	faComment,
	faHeart,
	faQuestion,
	faDownload,
	faTimesCircle,
	faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'

library.add(
	// Solid
	faChartPie,
	faBookOpen,
	faNotEqual,
	faBook,
	faCogs,
	faFilter,
	faAngleDoubleRight,
	faAngleRight,
	faAngleDown,
	faSortDown,
	faSortUp,
	faSearchMinus,
	faArrowAltCircleRight,
	faClipboard,
	faInfoCircle,
	faExclamationTriangle,
	faExclamationCircle,
	faCalendarAlt,
	faCheck,
	faTimes,
	faBug,
	faSyncAlt,
	faComment,
	faHeart,
	faQuestion,
	faDownload,
	faTimesCircle,
	faExternalLinkAlt
)
