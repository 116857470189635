import thunk from 'redux-thunk'
import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { createBrowserHistory } from 'history'

import reducer from './widgets'

export const history = createBrowserHistory()

export default function configureStore() {
	const initialState = {
		...window.__INITIAL_STATE___,
		activity: (window.__INITIAL_STATE___ && window.__INITIAL_STATE___.activity) || {}
	}
	const logger = createLogger()
	const middleware = process.env.NODE_ENV === 'development' ? applyMiddleware(thunk, logger) : applyMiddleware(thunk)
	const store = createStore(reducer, initialState, compose(middleware))

	// Hot reloading
	if (module.hot) {
		module.hot.accept('./widgets', () => {
			store.replaceReducer(reducer)
		})
	}
	return store
}
