import React, { useContext } from 'react'
import { Card, CardBody } from 'reactstrap'
import AppContext from '../../context/Context'
import { rgbaColor, capitalize, immediateOrNotChartColours } from '../../helpers/utils'
import { Pie } from 'react-chartjs-2'
import NoData from '../utilities/NoData'

function getImmediateOrNotOfBetsPlaced(bets) {
    return bets.reduce((acc, bet) => {
        const isImmediate = bet.immediate === true ? 'Yes' : 'No'

        acc[isImmediate] = acc[isImmediate] ? (acc[isImmediate] + 1) : 1

        return acc
    }, {})
}

const ImmediateOrNotCardPie = props => {
    const { isDark } = useContext(AppContext)

    const config = {
        data(canvas) {
            const immediateOrNotOfBetsPlaced = getImmediateOrNotOfBetsPlaced(props.bets)

            return Object.keys(immediateOrNotOfBetsPlaced).reduce((acc, isImmediate) => {
                acc.datasets[0].data.push(immediateOrNotOfBetsPlaced[isImmediate])
                acc.datasets[0].backgroundColor.push(isDark ? immediateOrNotChartColours.dark[isImmediate.toLowerCase()] : immediateOrNotChartColours.light[isImmediate.toLowerCase()])
                acc.datasets[0].borderWidth.push(1)
                acc.labels.push(capitalize(isImmediate))

                return acc
            }, {
                datasets: [
                    {
                        data: [],
                        backgroundColor: [],
                        borderWidth: []
                    }
                ],
                labels: []
            })
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: '# of Bets immediate or not',
                fontSize: 18,
                fontStyle: 'bold',
                lineHeight: 2.5,
                fontColor: rgbaColor('#cccccc', 0.7)
            },
            legend: {
                labels: {
                    fontSize: 12,
                    fontStyle: 'italic',
                    fontColor: rgbaColor('#cccccc', 0.7)
                },
                display: true,
                position: 'right'
            }
        }
    }

    return !props.bets.length ? (
        <NoData type="bets" canFilter={true}/>
    ) : (
        <Card className="mb-3">
            <CardBody className="rounded-soft bg-gradient">
                <Pie data={config.data} options={config.options}/>
            </CardBody>
        </Card>
    )
}

export default ImmediateOrNotCardPie