export const Dashboard = {
	name: 'Dashboard',
	to: '/',
	exact: true,
	icon: 'chart-pie'
}

// export const authenticationRoutes = {
//   name: 'Authentication',
//   to: '/authentication',
//   icon: 'lock',
//   children: [
//     {
//       to: '/authentication/basic',
//       name: 'Basic',
//       children: [
//         { to: '/authentication/basic/login', name: 'Login' },
//         { to: '/authentication/basic/logout', name: 'Logout' },
//         { to: '/authentication/basic/register', name: 'Register' },
//         { to: '/authentication/basic/forget-password', name: 'Forgot password' },
//         { to: '/authentication/basic/password-reset', name: 'Reset password' },
//         { to: '/authentication/basic/confirm-mail', name: 'Confirm mail' },
//         { to: '/authentication/basic/lock-screen', name: 'Lock screen' }
//       ]
//     },
//     {
//       to: '/authentication/card',
//       name: 'Card',
//       children: [
//         { to: '/authentication/card/login', name: 'Login' },
//         { to: '/authentication/card/logout', name: 'Logout' },
//         { to: '/authentication/card/register', name: 'Register' },
//         { to: '/authentication/card/forget-password', name: 'Forgot password' },
//         { to: '/authentication/card/password-reset', name: 'Reset password' },
//         { to: '/authentication/card/confirm-mail', name: 'Confirm mail' },
//         { to: '/authentication/card/lock-screen', name: 'Lock screen' }
//       ]
//     },
//     {
//       to: '/authentication/split',
//       name: 'Split',
//       children: [
//         { to: '/authentication/split/login', name: 'Login' },
//         { to: '/authentication/split/logout', name: 'Logout' },
//         { to: '/authentication/split/register', name: 'Register' },
//         { to: '/authentication/split/forget-password', name: 'Forgot password' },
//         { to: '/authentication/split/password-reset', name: 'Reset password' },
//         { to: '/authentication/split/confirm-mail', name: 'Confirm mail' },
//         { to: '/authentication/split/lock-screen', name: 'Lock screen' }
//       ]
//     },
//     {
//       to: '/authentication/wizard',
//       name: 'Wizard'
//     }
//   ]
// };

export const Bets = {
	name: 'Bets',
	to: '/bets',
	exact: true,
	icon: 'book-open'
}

export const Settings = {
	name: 'Settings',
	to: '/settings',
	exact: true,
	icon: 'cogs'
}

// export const Events = {
//   name: 'Events',
//   to: '/changelog',
//   exact: true,
//   icon: 'code-branch',
//   badge: {
//     text: `v${version}`,
//     color: 'soft-primary'
//   }
// };

export const Logs = {
	name: 'Logs',
	to: '/logs',
	exact: true,
	icon: 'clipboard'
}

export const Unmatched = {
	name: 'Unmatched',
	to: '/unmatched',
	exact: true,
	icon: 'not-equal'
}

export const Events = {
	name: 'Events',
	to: '/events',
	exact: true,
	icon: 'calendar-alt'
}

export default [Dashboard, Bets, Events, Logs, Unmatched, Settings]
