import React, { useContext } from 'react'
import { Card, CardBody } from 'reactstrap'
import AppContext from '../../context/Context'
import { rgbaColor, capitalize, executionTypeChartColours} from '../../helpers/utils'
import { Pie } from 'react-chartjs-2'
import NoData from '../utilities/NoData'

function getExecutionTypesOfBetsPlaced(bets) {
    return bets.reduce((acc, bet) => {
        // 'immediate' was the legacy executionType before keep was introduced
        // if no 'executionType' exists, it'll be 'immediate'
        const executionType = bet.executionType || 'immediate'

        acc[executionType] = acc[executionType] ? (acc[executionType] + 1) : 1
        
        return acc
    }, {})
}

const ExecutionTypesCardPie = props => {
    const { isDark } = useContext(AppContext)

    const config = {
        data(canvas) {
            const executionTypesOfBetsPlaced = getExecutionTypesOfBetsPlaced(props.bets)

            return Object.keys(executionTypesOfBetsPlaced).reduce((acc, type) => {
                acc.datasets[0].data.push(executionTypesOfBetsPlaced[type])
                acc.datasets[0].backgroundColor.push(isDark ? executionTypeChartColours.dark[type.toLowerCase()] : executionTypeChartColours.light[type.toLowerCase()])
                acc.datasets[0].borderWidth.push(1)
                acc.labels.push(capitalize(type))

                return acc
            }, {
                datasets: [
                    {
                        data: [],
                        backgroundColor: [],
                        borderWidth: []
                    }
                ],
                labels: []
            })
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: '# of Bets on Execution Type',
                fontSize: 18,
                fontStyle: 'bold',
                lineHeight: 2.5,
                fontColor: rgbaColor('#cccccc', 0.7)
            },
            legend: {
                labels: {
                    fontSize: 12,
                    fontStyle: 'italic',
                    fontColor: rgbaColor('#cccccc', 0.7)
                },
                display: true,
                position: 'right'
            }
        }
    }

    return !props.bets.length ? (
        <NoData type="bets" canFilter={true}/>
    ) : (
        <Card className="mb-3">
            <CardBody className="rounded-soft bg-gradient">
                <Pie data={config.data} options={config.options}/>
            </CardBody>
        </Card>
    )
}

export default ExecutionTypesCardPie