/**
 * CONSTANTS
 */
const FETCHED_BALANCES = 'FETCHED_BALANCES'
const FETCHED_DASHBOARD_BETS = 'FETCHED_DASHBOARD_BETS'
const FETCHED_ACTIVE_BETS = 'FETCHED_ACTIVE_BETS'
const FETCHED_ENDED_BETS = 'FETCHED_ENDED_BETS'
const FETCHED_BETS = 'FETCHED_BETS'
const FETCHED_CONFIG = 'FETCHED_CONFIG'
const FETCHED_EVENTS = 'FETCHED_EVENTS'
const FETCHED_ACTIVITY = 'FETCHED_ACTIVITY'
const FETCHED_LOGS = 'FETCHED_LOGS'
const FETCHED_UNMATCHED = 'FETCHED_UNMATCHED'
const FETCHED_UNMATCHED_MARKETS = 'FETCHED_UNMATCHED_MARKETS'
const SET_DASHBOARD_TIME_PERIOD = 'SET_DASHBOARD_TIME_PERIOD'
const FETCHED_MARKETS = 'FETCHED_MARKETS'
const initialState = {
	...window.__INITIAL_STATE__,
	activity: (window.__INITIAL_STATE__ && window.__INITIAL_STATE__.activity) || {}
}

/**
 * REDUCER
 */
export default function(state = initialState, action = {}) {
	switch (action.type) {
		case FETCHED_BALANCES:
			return {
				...state,
				balances: action.payload
			}
		case FETCHED_DASHBOARD_BETS:
			return {
				...state,
				dashboardBets: action.payload
			}
		case FETCHED_ACTIVE_BETS:
			return {
				...state,
				activeBets: action.payload
			}
		case FETCHED_ENDED_BETS: 
			return {
				...state,
				endedBets: action.payload
			}
		case FETCHED_BETS:
			return {
				...state,
				bets: action.payload
			}
		case FETCHED_CONFIG:
			return {
				...state,
				config: action.payload
			}
		case FETCHED_EVENTS:
			return {
				...state,
				events: action.payload
			}
		case FETCHED_UNMATCHED:
			return {
				...state,
				unmatched: action.payload
			}
		case FETCHED_ACTIVITY:
			return {
				...state,
				activity: action.payload
			}
		case FETCHED_MARKETS:
			return {
				...state,
				markets: {
					...(state.markets || {}),
					[action.payload.eventId]: action.payload.markets
				}
			}
		case FETCHED_UNMATCHED_MARKETS:
			return {
				...state,
				unmatchedMarkets: {
					...(state.unmatchedMarkets || {}),
					[action.payload.eventId]: action.payload.unmatched
				}
			}
		case FETCHED_LOGS:
			return {
				...state,
				logs: action.payload
			}
		case SET_DASHBOARD_TIME_PERIOD:
			return {
				...state,
				timePeriod: action.payload
			}
		default:
			return state
	}
}

/**
 * ACTIONS
 */
export const fetchedBalances = balances => dispatch => {
	dispatch({
		type: FETCHED_BALANCES,
		payload: balances
	})
}

export const fetchedBets = bets => dispatch => {
	dispatch({
		type: FETCHED_BETS,
		payload: bets
	})
}

export const fetchedDashboardBets = dashboardBets => dispatch => {
	dispatch({
		type: FETCHED_DASHBOARD_BETS,
		payload: dashboardBets
	})
}

export const fetchedActiveBets = activeBets => dispatch => {
	dispatch({
		type: FETCHED_ACTIVE_BETS,
		payload: activeBets
	})
}

export const fetchedEndedBets = endedBets => dispatch => {
	dispatch({
		type: FETCHED_ENDED_BETS,
		payload: endedBets
	})
}

export const fetchedConfig = config => dispatch => {
	dispatch({
		type: FETCHED_CONFIG,
		payload: config
	})
}

export const fetchedActivity = activity => dispatch => {
	dispatch({
		type: FETCHED_ACTIVITY,
		payload: activity
	})
}

export const fetchedLogs = logs => dispatch => {
	dispatch({
		type: FETCHED_LOGS,
		payload: logs
	})
}

export const setTimePeriod = timePeriod => dispatch => {
	dispatch({
		type: SET_DASHBOARD_TIME_PERIOD,
		payload: timePeriod
	})
}

export const fetchedEvents = events => dispatch => {
	dispatch({
		type: FETCHED_EVENTS,
		payload: events
	})
}

export const fetchedUnmatched = unmatched => dispatch => {
	dispatch({
		type: FETCHED_UNMATCHED,
		payload: unmatched
	})
}

export const fetchedMarkets = (eventId, markets) => dispatch => {
	dispatch({
		type: FETCHED_MARKETS,
		payload: {
			eventId,
			markets
		}
	})
}

export const fetchedUnmatchedMarkets = (eventId, unmatched) => dispatch => {
	dispatch({
		type: FETCHED_UNMATCHED_MARKETS,
		payload: {
			eventId,
			unmatched
		}
	})
}