import React, { useContext } from 'react'
import { Collapse, Nav, Navbar } from 'reactstrap'
import Scrollbar from 'react-scrollbars-custom'

import { navbarBreakPoint } from '../../config'
import Logo from './Logo'
import routes from '../../routes'
import NavbarVerticalMenu from './NavbarVerticalMenu'
import AppContext from '../../context/Context'

const isBurgerMenuShown = () => {
	const burgerMenu = document.getElementById('burgerMenu')
	return !(getComputedStyle(burgerMenu).display === 'none')
}

const NavbarVertical = () => {
	const { /* isFluid, setIsFluid, isRTL, setIsRTL, isDark, setIsDark,*/ showBurgerMenu, setShowBurgerMenu } = useContext(AppContext)

	const handleNavbarVerticalCollapse = () => isBurgerMenuShown() && setShowBurgerMenu(!showBurgerMenu)

	return (
		<Navbar expand={navbarBreakPoint} className="navbar-vertical navbar-glass" light>
			<Logo at="navbar-vertical" width={40} />
			<Collapse navbar isOpen={showBurgerMenu}>
				<Scrollbar
					style={{ height: '35vh', display: 'block' }}
					rtl={false}
					trackYProps={{
						renderer: props => {
							const { elementRef, ...restProps } = props
							return <span {...restProps} ref={elementRef} className="TrackY" />
						}
					}}
				>
					<Nav navbar vertical>
						<NavbarVerticalMenu routes={routes} handleNavbarVerticalCollapse={handleNavbarVerticalCollapse} />
					</Nav>
				</Scrollbar>
			</Collapse>
		</Navbar>
	)
}

export default NavbarVertical
