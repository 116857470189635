import { createContext } from 'react'

const AppContext = createContext({
	isDark: true,
	showBurgerMenu: false, // controls showing vertical nav on mobile
	currency: '£',
	version: ''
})

export const EmailContext = createContext({
	emails: []
})

export const ProductContext = createContext({
	products: []
})

export const FeedContext = createContext({
	feeds: []
})

export const AuthWizardContext = createContext({
	user: {}
})

export default AppContext
