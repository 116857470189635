import React, { useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardBody, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import Background from '../common/Background'
import corner1 from '../../assets/img/illustrations/corner-1.png'
import corner2 from '../../assets/img/illustrations/corner-2.png'
import corner3 from '../../assets/img/illustrations/corner-3.png'
import { exchangeChartColors, getDisplayAmount } from '../../helpers/utils'

const getImage = color => {
	switch (color) {
		case 'warning':
			return corner1
		case 'info':
			return corner2
		case 'success':
			return corner3
		default:
			return corner1
	}
}

const getContentClassNames = (color, hasBets) => {
	const mb = hasBets ? 'bets-summary' : 'mb-2'
	const contentClassNames = `display-4 fs-4 ${mb} font-weight-normal text-sans-serif`

	if (color === 'success') {
		return contentClassNames
	}
	return `${contentClassNames} text-${color}`
}

const CardSummary = props => {
	const [ hasBets, setHasBets ] = useState(false)
	const [ totalActiveBets, setTotalActiveBets ] = useState(0)
	const [ totalEndedBets, setTotalEndedBets ] = useState(0)

	let _actives = {}
	let sortedExchangeDifferences

	useEffect(() => {
		if (props.activeBets || props.endedBets) {
			setHasBets(true)
			setTotalActiveBets((props.activeBets?.successful?.length || 0) + (props.activeBets?.failing?.length || 0))
			setTotalEndedBets((props.endedBets?.successful?.length || 0) + (props.endedBets?.failing?.length || 0))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (props.config?.companies) {
		_actives = props.config.companies.reduce((acc, comp) => {
			acc[comp.name.toLowerCase()] = comp.active ? 'active' : 'inactive'

			return acc
		}, {})
	}
	if (props.exchangeDifferences) {
		sortedExchangeDifferences = Object.keys(props.exchangeDifferences).sort((a, b) => {
			const [_a, _b] = [_actives[a.toLowerCase()], _actives[b.toLowerCase()]]

			if (_a !== _b) {
				return (_a === 'active') ? -1 : 1
			}
			return props.exchangeDifferences[b] - props.exchangeDifferences[a]
		}).reduce((acc, ex) => {
			acc[ex] = props.exchangeDifferences[ex]

			return acc
		}, {})
	}
	return (
		<Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
			<Background image={getImage(props.color)} className="bg-card" />
			<CardBody className="position-relative">
				<h6>
					{props.title}
					{ sortedExchangeDifferences && (
						<span style={{float: 'right'}}>
							{ Object.keys(sortedExchangeDifferences).map((ex, idx) => (
								<span className="badge rounded-capsule ml-2" style={{
									backgroundColor: exchangeChartColors.dark[_actives[ex.toLowerCase() || 'active']][ex.toLowerCase()],
									fontSize: _actives[ex.toLowerCase()] === 'active' ? (10 - idx) : 6
								}}>{getDisplayAmount(sortedExchangeDifferences[ex])}</span>
							))}
						</span>
					)}
				</h6>
				<div className={getContentClassNames(props.color, hasBets)}>
					{props.children}
				</div>
				{ props.activeBets && props.endedBets && (
					<>
						<span className="bets-summary-total" style={{
							position: 'absolute',
							color: 'green'
						}}>{totalActiveBets} active bet(s)</span>
						<span className="bets-summary-total" style={{
							color: 'grey'
						}}>{totalEndedBets} ended bet(s)</span>
					</>
				)}
				{ props.linkText && props.to && (
					<Link className="font-weight-semi-bold fs--1 text-nowrap" to={props.to}>
						{props.linkText}
						<FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
					</Link>
				) }
				{ props.linkText && props.handler && (
					<Button color="link" className="link-btn" onClick={props.handler} style={{display: 'block'}}>
						{props.linkText}
						<FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
					</Button>
				) }
			</CardBody>
		</Card>
	)
}

CardSummary.propTypes = {
	title: PropTypes.string.isRequired,
	rate: PropTypes.string,
	linkText: PropTypes.string,
	to: PropTypes.string,
	color: PropTypes.string,
	children: PropTypes.node
}

export default CardSummary
