import React, { useContext } from 'react'
import { Card, CardBody } from 'reactstrap'
import AppContext from '../../context/Context'
import { rgbaColor, capitalize, exchangeChartColors } from '../../helpers/utils'
import { Pie } from 'react-chartjs-2'
import NoData from '../utilities/NoData'

function getBetsPlacedOnExchanges(bets) {
	return bets.reduce((acc, bet) => {
		for (const idx in bet.runners) {
			const upEx = bet.runners[idx].exchange.toUpperCase()

			acc[upEx] = acc[upEx] ? acc[upEx] + 1 : 1
		}
		return acc
	}, {})
}

const ExchangeBetsCardPie = props => {
	const { isDark } = useContext(AppContext)

	const config = {
		data(canvas) {
			const betsPlacedOnExchanges = getBetsPlacedOnExchanges(props.bets)

			return Object.keys(betsPlacedOnExchanges).reduce(
				(acc, ex) => {
					const lowEx = ex.toLowerCase()
					const companyEntry = props.config.companies.find(_entry => {
						return _entry.name.toLowerCase() === lowEx
					})
					const activityProp = companyEntry.active ? 'active' : 'inactive'

					acc.datasets[0].data.push(betsPlacedOnExchanges[ex])
					acc.datasets[0].backgroundColor.push(isDark
						? exchangeChartColors.dark[activityProp][lowEx]
						: exchangeChartColors.light[activityProp][lowEx])
					acc.datasets[0].borderWidth.push(1)
					acc.labels.push(capitalize(ex))

					return acc
				},
				{
					datasets: [
						{
							data: [],
							backgroundColor: [],
							borderWidth: []
						}
					],
					labels: []
				}
			)
		},
		options: {
			responsive: true,
			title: {
				display: true,
				text: '# of Exchange bets',
				fontSize: 18,
				fontStyle: 'bold',
				lineHeight: 2.5,
				fontColor: rgbaColor('#cccccc', 0.7)
			},
			legend: {
				labels: {
					fontSize: 12,
					fontStyle: 'italic',
					fontColor: rgbaColor('#cccccc', 0.7)
				},
				display: true,
				position: 'right'
			}
		}
	}

	return !props.bets.length ? (
		<NoData type="bets" canFilter={true}/>
	) : (
		<Card className="mb-3">
			<CardBody className="rounded-soft bg-gradient">
				<Pie data={config.data} options={config.options} />
			</CardBody>
		</Card>
	)
}

export default ExchangeBetsCardPie
