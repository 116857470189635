import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardBody, Button, Col, Form, Label, Row } from 'reactstrap'
import Datetime from 'react-datetime'
import Background from '../common/Background'
import { getImage } from '../../helpers/utils'

const CardForm = props => {
	const [_startDate, _setStartDate] = useState(props.startDate)
	const [_endDate, _setEndDate] = useState(props.endDate)

	const handleSubmit = e => {
		e.preventDefault()

		props.handleSubmit(_startDate, _endDate)
	}

	return (
		<Card className="mb-3" style={{ minWidth: '12rem' }}>
			<Background image={getImage(props.color)} className="bg-card" />
			<CardBody tag={Form} className="position-relative" onSubmit={handleSubmit}>
				{ props.title && (
					<h6>{props.title}</h6>
				) }
				{ props.inline ? (
					<>
						<Row>
							<Col md={5} lg={5} style={{
								paddingRight: 0
							}}>
								<Label for="from-date" lg={12} style={{fontSize: '0.8rem', paddingLeft: 0, paddingRight: 0}}>From:</Label>
								<Col md={12} lg={12} style={{paddingLeft: 0, paddingRight: 0}}>
									<Datetime
										value={_startDate}
										onChange={_setStartDate}
										inputProps={{
											id: 'from-date',
											autoComplete: 'off'
										}}
									/>
								</Col>
							</Col>
							<Col md={5} lg={5} style={{
								paddingRight: 0
							}}>
								<Label for="to-date" md={12} lg={12} style={{fontSize: '0.8rem', paddingRight: 0, paddingLeft: 0}}>To:</Label>
								<Col md={12} lg={12} style={{paddingLeft: 0, paddingRight: 0}}>
									<Datetime
										value={_endDate}
										onChange={_setEndDate}
										inputProps={{
											id: 'to-date',
											autoComplete: 'off'
										}}
									/>
								</Col>
							</Col>
							<Col md={2} lg={2} style={{
								paddingRight: 0
							}}>
								<Button target="_blank" size="lg" color="success" style={{position: 'absolute', bottom: 0}}>
									<FontAwesomeIcon icon="angle-double-right"/>
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row>
							<Label for="from-date" md={12} lg={3} style={{fontSize: '0.8rem'}}>From:</Label>
							<Col md={12} lg={9}>
								<Datetime
									value={_startDate}
									onChange={_setStartDate}
									inputProps={{
										id: 'from-date',
										autoComplete: 'off'
									}}
								/>
							</Col>
						</Row>
						<Row style={{marginTop: 10}}>
							<Label for="to-date" md={12} lg={3} style={{fontSize: '0.8rem'}}>To:</Label>
							<Col md={12} lg={9}>
								<Datetime
									value={_endDate}
									onChange={_setEndDate}
									inputProps={{
										id: 'to-date',
										autoComplete: 'off'
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={12} style={{marginTop: '1rem' }}>
								<Button target="_blank" size="lg" color="success" style={{ width: '100%' }}>Search
									<FontAwesomeIcon icon="angle-double-right" style={{marginLeft: 10}}/>
								</Button>
							</Col>
						</Row>
					</>
				)}
			</CardBody>
		</Card>
	)
}

export default CardForm
