import axios from 'axios'

const baseAxiosConfig = {
	auth: {
		username: process.env.REACT_APP_EXCHANGE_API_USERNAME || 'user',
		password: process.env.REACT_APP_EXCHANGE_API_PASSWORD || 'pass'
	},
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
}

export default {
	Database: axios.create({
		baseURL: process.env.REACT_APP_DATABASE_API,
		...baseAxiosConfig
	}),
	Logs: axios.create({
		baseURL: process.env.REACT_APP_LOGS_API,
		...baseAxiosConfig
	})
}
