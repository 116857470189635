function detachStylesheets() {
	Array.from(document.getElementsByClassName('theme-stylesheet')).forEach(style => {
		style.remove()
	})
}

function insertStylesheet({ isDark }) {
	const link = document.createElement('link')
	link.href = `${process.env.PUBLIC_URL}/css/theme${isDark ? '-dark' : ''}.css`
	link.type = 'text/css'
	link.rel = 'stylesheet'
	link.className = 'theme-stylesheet'
	document.getElementsByTagName('head')[0].appendChild(link)
	document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr')
}

export default ({ isDark }) => {
	detachStylesheets()
	insertStylesheet({ isDark })
}
