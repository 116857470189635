import React, { useState, useContext } from 'react'
import { Col, Collapse, Table, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    getDisplayAmount,
    getDisplayDate,
    capitalize,
	BetNamePropPair,
	marketTypeFormat
} from '../../helpers/utils'
import FailuresModal from '../FailuresModal'
import AppContext from '../../context/Context'

function getOutcome(runner) {
	if (runner.outcome) {
		if (runner.outcome === 'LOSE') {
			return 'rgba(139, 0, 0, 0.4)'
		}
		if (runner.outcome === 'VOID') {
			return 'rgb(128, 128, 0)'
		}
		return 'rgba(0, 128, 0, 0.4)'
	}
	return 'transparent'
}

const BetDetails = props => {
    const { isDark } = useContext(AppContext)
    const [ isRunnersOpen, setIsRunnersOpen ] = useState({})
    const [ isNetProfitsOpen, setIsNetProfitsOpen ] = useState({})
	const [ isGrossProfitsOpen, setIsGrossProfitsOpen ] = useState({})
	const [ isFailuresModalOpen, setIsFailuresModalOpen ] = useState({})

    const runnersToggle = (e, idx) => {
        e.preventDefault()
        e.stopPropagation()

		setIsRunnersOpen({
			...isRunnersOpen,
			[idx]: !isRunnersOpen[idx]
		})
	}

	const netProfitsToggle = (e, idx) => {
        e.preventDefault()
        e.stopPropagation()

		setIsNetProfitsOpen({
			...isNetProfitsOpen,
			[idx]: !isNetProfitsOpen[idx]
		})
	}

	const grossProfitsToggle = (e, idx) => {
        e.preventDefault()
        e.stopPropagation()

		setIsGrossProfitsOpen({
			...isGrossProfitsOpen,
			[idx]: !isGrossProfitsOpen[idx]
		})
	}

	const handleOpenFailuresModalClick = (eventId, marketId, runnerId) => {
		const key = `${eventId}-${marketId}-${runnerId}`

		setIsFailuresModalOpen({
			...isFailuresModalOpen,
			[key]: !isFailuresModalOpen[key]
		})
	}

	const getFailuresCell = (runner = {}) => {
		if (runner.failures && runner.failures.length) {
			return (
				<Button
					color="link"
					onClick={() => handleOpenFailuresModalClick(runner.eventId, runner.marketId, runner.runnerId)}
					style={{fontSize: '0.75rem', fontStyle: 'italic', padding: 0}}>{runner.failures.length}</Button>
			)
		}
		return 0
	}

    const getDisplayRunners = (subType, runners, idx) => {
		if (runners && typeof runners === 'object' && Array.isArray(runners)) {
			return (
				<>
					<span onClick={(e) => runnersToggle(e, idx)}>
						<div style={{padding: '0 7px 0 7px', display: 'inline', border: '1px solid'}}>
							<FontAwesomeIcon
								icon="angle-right"
								className={`info-toggle ${isRunnersOpen[idx] ? 'down' : 'up'}`}/>
						</div>
						{ !isRunnersOpen[idx] && (
							<h6 style={{display: 'inline-block', marginLeft: 10, letterSpacing: 3}}>[...]</h6>
						) }
					</span>
					<Collapse isOpen={isRunnersOpen[idx]}>
						<Table size="sm" dark={isDark} responsive style={{tableLayout: 'fixed', minWidth: '1050px'}} className="bet-details-table">
							<thead>
								<tr>
									<th style={{width: '100px'}}>Exchange</th>
									{ subType === 'BackBack' ? (
										<th style={{width: '100px'}}>Outcome</th>
									) : (
										<th style={{width: '100px'}}>Side</th>
									)}
									<th style={{width: '150px'}}>Bet ID</th>
									{/*<th style={{width: '125px'}}>Event ID</th>*/}
									{/* <th style={{width: '125px'}}>Market ID</th> */}
									<th style={{width: '200px'}}>Market Name</th>
									{/* <th style={{width: '125px'}}>Runner ID</th> */}
									<th style={{width: '150px'}}>Runner Name</th>
									<th style={{width: '75px'}}>Stake</th>
									<th style={{width: '75px'}}>Matched</th>
									<th style={{width: '100px'}}>Unmatched</th>
									<th style={{width: '50px'}}>Price</th>
									<th style={{width: '100px'}}>Status</th>
									<th style={{width: '75px'}}>Failures</th>
									<th style={{width: '150px'}}>Time Matched</th>
								</tr>
							</thead>
							<tbody>
								{ runners.map((runner, idx) => {
									return (
										<tr key={idx} style={{
											backgroundColor: getOutcome(runner)
										}}>
											<td>{capitalize(runner.exchange || '-')}</td>
											{ subType === 'BackBack' ? (
												<td>{capitalize(runner.outcomeSide || '-')}</td>
											) : (
												<td>{capitalize(runner.side || '-')}</td>
											)}
											<td>{runner.betId || '-'}</td>
											{/* <td>{runner.eventId || '-'}</td> */}
											{/* <td>{runner.marketId || '-'}</td> */}
											<td>{runner.marketName || '-'}</td>
											{/* <td>{runner.runnerId}</td> */}
											<td>{runner.name || '-'}</td>
											<td>{getDisplayAmount(runner.stake)}</td>
											<td>{getDisplayAmount(runner.matched)}</td>
											<td>{getDisplayAmount(runner.unmatched)}</td>
											<td>{getDisplayAmount(runner.price, true)}</td>
											<td>{runner.status}</td>
											<td>{getFailuresCell(runner)}</td>
											<td>{getDisplayDate(runner.timeMatched)}</td>
											<FailuresModal isOpen={isFailuresModalOpen[`${runner.eventId}-${runner.marketId}-${runner.runnerId}`]} runner={runner} toggleModal={() => handleOpenFailuresModalClick(runner.eventId, runner.marketId, runner.runnerId)}/>
										</tr>
									)
								}) }
							</tbody>
						</Table>
					</Collapse>
				</>
			)
		}
		return <p>-</p>
	}

	const getDisplayValue = (key, val, idx, subType) => {
		switch (key) {
			case 'result':
				if (!val) {
					return <p>-</p>
				}
				if (typeof val === 'string') {
					return <p>{val}</p>
				}
				if (typeof val === 'object') {
					// Was just looking if 'lossAllowed' was truthy
					// But might be 0 which is falsey so wouldn't work
					if (val.message) {
						if (val.hasOwnProperty('lossAllowed')) {
							return <p>{val.message} ({Math.round(val.lossAllowed * 100)}% loss allowed)</p>
						}
						return <p>{val.message}</p>
					}
				}
				return <p>{val}</p>
			case '_eventId':
				return <p>{val || '-'}</p>
			case 'immediate':
				if (val === undefined) {
					return <p>-</p>
				}
				return <p>{(val) ? 'Yes' : 'No'}</p>
			case 'eventType':
				return <p>{capitalize(val || '-')}</p>
			case 'generalMarketType':
				return <p>{marketTypeFormat(val || '-')}</p>
			case 'startTime':
			case 'date':
			case 'completedDate':
			case 'incompletedDate':
				return getDisplayDate(val)
			case 'netProfits':
				if (val && typeof val === 'object' && Object.keys(val).length) {
					return (
						<>
							<span onClick={(e) => netProfitsToggle(e, idx)}>
								<div style={{padding: '0 7px 0 7px', display: 'inline', border: '1px solid'}}>
									<FontAwesomeIcon
										icon="angle-right"
										className={`info-toggle ${isNetProfitsOpen[idx] ? 'down' : 'up'}`}/>
								</div>
								{ !isNetProfitsOpen[idx] && (
									<h6 style={{display: 'inline-block', marginLeft: 10, letterSpacing: 3}}>{'{...}'}</h6>
								) }
							</span>
							<Collapse isOpen={isNetProfitsOpen[idx]}>
								<ul style={{listStyleType: 'none'}}>
									{ Object.keys(val).map(key => (
										<li>{`${capitalize(key || '-')}: £${(val[key] || 0).toFixed(2)}`}</li>
									)) }
								</ul>
							</Collapse>
						</>
					)
				}
				return <p>-</p>
			case 'grossProfits':
				if (val && typeof val === 'object' && Object.keys(val).length) {
					return (
						<>
							<span onClick={(e) => grossProfitsToggle(e, idx)}>
								<div style={{padding: '0 7px 0 7px', display: 'inline', border: '1px solid'}}>
									<FontAwesomeIcon
										icon="angle-right"
										className={`info-toggle ${isGrossProfitsOpen[idx] ? 'down' : 'up'}`}/>
								</div>
								{ !isGrossProfitsOpen[idx] && (
									<h6 style={{display: 'inline-block', marginLeft: 10, letterSpacing: 3}}>{'{...}'}</h6>
								) }
							</span>
							<Collapse isOpen={isGrossProfitsOpen[idx]}>
								<ul style={{listStyleType: 'none'}}>
									{ Object.keys(val).map(key => (
										<li>{`${capitalize(key || '-')}: £${val[key].toFixed(2)}`}</li>
									)) }
								</ul>
							</Collapse>
						</>
					)
				}
				return <p>-</p>
			case 'executionType':
				if (val) {
					return <p>{capitalize(val)}</p>
				}
				return <p>-</p>
			default:
				return <p>{val || '-'}</p>
		}
    }

    return (
        <>
            { Object.keys(BetNamePropPair).map((name, _idx) => (
                <Col xs={(props.size === 'lg') ? 4 : 6}
                    sm={(props.size === 'lg') ? 4 : 6}
                    md={(props.size === 'lg') ? 3 : 4}
                    lg={(props.size === 'lg') ? 3 : 4} key={_idx}>
                    <h6 className="text-muted">{BetNamePropPair[name]}</h6>
                    {getDisplayValue(name, props.bet[name], props.idx, props.bet.subType)}
                </Col>
            )) }
            <Col xs={12} sm={12} md={12} lg={12}>
                <h6 className="text-muted" style={{marginTop: '1rem'}}>Runners</h6>
                {getDisplayRunners(props.bet.subType, props.bet.runners, props.idx)}
            </Col>
        </>
    )
}

export default BetDetails