import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import DashboardLayout from './DashboardLayout'
import ErrorLayout from './ErrorLayout'

// import loadable from '@loadable/component';
// import WizardLayout from '../components/auth/wizard/WizardLayout';

//const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
// const Landing = loadable(() => import('../components/landing/Landing'));
// const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));
// const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));
// const AuthWizardRoutes = loadable(() => import('../components/auth/wizard/AuthWizardRoutes'));

const Layout = () => {
	useEffect(() => {
		// AuthBasicLayout.preload();
		// Landing.preload();
		// AuthCardRoutes.preload();
		// AuthSplitRoutes.preload();
		// AuthWizardRoutes.preload();
	}, [])

	return (
		<Router fallback={<span />}>
			<Switch>
				<Route path="/errors" component={ErrorLayout} />
				<Route component={DashboardLayout} />
			</Switch>
		</Router>
	)
}

export default Layout
