import React, { useContext, useEffect, useRef, useState } from 'react'
import { Card, CardBody, Button } from 'reactstrap'
import { Line, Chart } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'
import zoomPlugin from 'chartjs-plugin-zoom'
import flatten from 'lodash/flatten'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { rgbaColor, exchangeChartColors, capitalize, exchanges } from '../../helpers/utils'
import AppContext from '../../context/Context'
import Apis from '../../apis'
import dayjs from 'dayjs'
import customParsedFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import { Parser as HtmlToReactParser } from 'html-to-react'

const htmlToReactParser = new HtmlToReactParser()

dayjs.extend(customParsedFormat)
dayjs.extend(isBetween)

Chart.pluginService.register(annotationPlugin)
Chart.pluginService.register(zoomPlugin)

function getDepositsAndWithdrawals(activities) {
	return activities.reduce((acc, activity) => {
		const lowEx = activity.exchange.toLowerCase()

		if (['DEPOSIT', 'WITHDRAWAL'].includes(activity.type)) {
			if (acc[activity.type][lowEx]) {
				acc[activity.type][lowEx].push(activity)
			} else {
				acc[activity.type][lowEx] = [activity]
			}
		}
		// if (activity.betfair) {
		// 	acc[type].betfair = activity.betfair.filter(s => {
		// 		return s.legacyData.marketName === type
		// 	}).map(s => {
		// 		return {
		// 			exchange: 'BETFAIR',
		// 			type: type,
		// 			amount: s.amount,
		// 			date: getFormattedDate(s.itemDate)
		// 		}
		// 	})
		// }
		// if (activity.betdaq) {
		// 	acc[type].betdaq = activity.betdaq.filter(s => {
		// 		return s.PostingCategory === 3 && s.Description.toUpperCase().includes(`${type}:`)
		// 	}).map(s => {
		// 		return {
		// 			exchange: 'BETDAQ',
		// 			type: type,
		// 			amount: s.Amount,
		// 			date: getFormattedDate(s.PostedAt)
		// 		}
		// 	})
		// }
		// if (activity.smarkets) {
		// 	const typeToGet = (type === 'DEPOSIT') ? 'deposit' : 'withdraw'

		// 	acc[type].smarkets = activity.smarkets.filter(s => {
		// 		return s.source === typeToGet
		// 	}).map(s => {
		// 		return {
		// 			exchange: 'SMARKETS',
		// 			type: type,
		// 			amount: parseFloat(s.money_change),
		// 			date: getFormattedDate(s.timestamp)
		// 		}
		// 	})
		// }
		return acc
	}, {
		DEPOSIT: {},
		WITHDRAWAL: {}
	})
}

function getFormattedDate(date) {
	const parsed = dayjs(date)
	const day = parsed
		.date()
		.toString()
		.padStart(2, '0')
	// Unsure why have to add 1 but don't care really
	const mnth = (parsed
		.month() + 1)
		.toString()
		.padStart(2, '0')
	const yr = parsed
		.year()
		.toString()
		.padStart(2, '0')
	const hr = parsed
		.hour()
		.toString()
		.padStart(2, '0')
	const min = parsed
		.minute()
		.toString()
		.padStart(2, '0')

	return `${day}/${mnth}/${yr} @ ${hr}:${min}`
}

function getXAxis(balances, annotations) {
	const balanceDates = balances.map(entry => {
		return getFormattedDate(entry.date)
	})
	const annotationDates = annotations.map(ann => {
		return ann.value
	})
	const combinedDates = [
		...balanceDates,
		...annotationDates
	]

	return combinedDates.sort((a, b) => {
		const [ parsedA, parsedB ] = [ dayjs(a, 'DD/MM/YYYY @ HH:mm'), dayjs(b, 'DD/MM/YYYY @ HH:mm')]

		return new Date(parsedA) - new Date(parsedB)
	})
}

const ProfitsLineChart = props => {
	const { isDark } = useContext(AppContext)
	const [ _legend, _setLegend ] = useState('')
	const [ isRefreshing, setIsRefreshing ] = useState(false)
	const [ isComparing, setIsComparing ] = useState(false)
	// eslint-disable-next-line
	const [ currentEnd, setCurrentEnd ] = useState()
	const depositsAndWithdrawals = getDepositsAndWithdrawals(props.activity.activities)
	const annotations = Object.values(depositsAndWithdrawals).reduce((acc, exs) => {
		let _idx = -1

		const newEntries = Object.values(exs).map(entries => {
			return entries.map(entry => {
				_idx++

				const lowEx = entry.exchange.toLowerCase()
				const companyEntry = props.config.companies.find(_entry => {
					return _entry.name.toLowerCase() === lowEx
				})
				const activityProp = companyEntry.active ? 'active' : 'inactive'
				const formattedDate = getFormattedDate(entry.date)
				const [ pounds, pence ] = String(entry.amount).split('.')
				const trueAmount = `£${pounds}.${pence ? pence.padStart(2, '0') : '00'}`

				return {
					type: 'line',
					mode: 'vertical',
					scaleID: 'x-axis-0',
					value: formattedDate,
					borderColor: isDark
						? exchangeChartColors.dark[activityProp][lowEx]
						: exchangeChartColors.light[activityProp][lowEx],
					borderWidth: 3,
					borderDash: [3, 3],
					label: {
						backgroundColor: isDark
							? exchangeChartColors.dark[activityProp][lowEx]
							: exchangeChartColors.light[activityProp][lowEx],
						content: [capitalize(entry.exchange), capitalize(entry.type), trueAmount.replace('-', ''), formattedDate],
						enabled: false,
						// Just stagger them so not getting in each other's way as can't get on hover working atm
						position: _idx % 2 === 0 ? 'top' : 'bottom',
						fontSize: 8,
						fontStyle: 'italic'
					},
					onMouseenter: function(e) {
						const position = this.chartInstance.canvas.getBoundingClientRect()
						const annotationEl = document.createElement('div')
						const labels = this._model.labelContent
						const fullOpacity = this._model.borderColor.replace('0.9', '1')

						annotationEl.id = 'annotation-label'
						annotationEl.innerHTML = 	`<div class="self-arrow" style="left: 84px; background-color: ${fullOpacity};"></div>
													<div style="position: absolute; z-index: 2">
														<table style="color: black; z-index: 100">
															<tbody>
																<tr>
																	<td>
																		<b>Exchange</b>
																	</td>
																	<td>
																		<em>${labels[0]}</em>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b>Type</b>
																	</td>
																	<td>
																		<em>${labels[1]}</em>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b>Amount</b>
																	</td>
																	<td>
																		<em>${labels[2]}</em>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b>Date</b>
																	</td>
																	<td>
																		<em>${labels[3]}</em>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>`
						annotationEl.style.borderRadius = '10px'
						annotationEl.style.border = `1px solid ${this._model.borderColor}`
						annotationEl.style.backgroundColor = fullOpacity
						annotationEl.style.padding = '6px'
						annotationEl.style.width = '180px'
						annotationEl.style.height = '63px'
						annotationEl.style.left = `${this._model.x1 + position.x - 85 + window.pageXOffset}px`
						annotationEl.style.top = `${position.y - 10 + window.pageYOffset}px`
						annotationEl.style.position = 'absolute'
						annotationEl.style.fontSize = '10px'
						annotationEl.style.color = 'black'
						annotationEl.style.lineHeight = '1em'

						document.body.appendChild(annotationEl)
					},
					onMouseleave: function(e) {
						document.getElementById('annotation-label').remove()
					}
				}
			})
		})

		return [
			...acc,
			...flatten(newEntries)
		]
	}, [])

	const config = {
		data(canvas) {
			let datasets = exchanges.map(exchange => {
				return {
					exchange,
					data: props.balances.balances.reduce((acc, entry) => {
						const entryForExchange = entry.balances.find(balance => {
							return balance.exchange.toUpperCase() === exchange.toUpperCase()
						})

						if (entryForExchange) {
							acc.push({
								date: entry.date,
								balance: entryForExchange.balance
							})
						}
						return acc
					}, [])
				}
			})
			const labels = getXAxis(props.balances.balances, annotations)
			const annotationsParsedDates = annotations.map(annotation => {
				return dayjs(annotation.value, 'DD/MM/YYYY @ HH:mm')
			})

			return {
				labels,
				datasets: datasets.map(set => {
					const lowEx = set.exchange.toLowerCase()
					const companyEntry = props.config.companies.find(_entry => {
						return _entry.name.toLowerCase() === lowEx
					})
					const activityProp = companyEntry.active ? 'active' : 'inactive'
					const _data = set.data.map((entry, idx) => {
						const _balance = entry.balance.toFixed(2)
						const thisDate = dayjs(entry.date)

						let annotationsBetween = []

						// The reason for this is that we could have a 'labels' array of length 32
						// but only a 'set.data' length of 28 because the 'labels' array is a combination of the balances & annotations
						// whereas 'set.data' is only the balances
						//
						// This caused the chart to look incomplete as was not displaying balances up until the end of the x-axis
						// To get around this & make 'set.data' the length of 'labels', we write duplicate values into the 'data'
						// If you have 2 takes of balances, 1 @ 10am & the other @ 11am. If you also have a withdrawal that happened @ 10:30am
						// The balance of the wallet before the withdrawal is the same as it was @ 10am, right? (Yes, it is)
						// Annotations have to class in the 'labels' array to be displayed, that is non-negotiable
						// So...if there are annotations between this point & the next one (or before/after - depending on index)
						// we duplicate the balance value to make it up that 32 length that 'labels' is
						//
						// This is correct. It makes logical sense. Trust me :)
						if (idx === 0) {
							annotationsBetween = annotationsParsedDates.filter(_date => {
								return _date.isBefore(thisDate)
							})
						} else if (set.data[idx + 1]) {
							const nextDate = dayjs(set.data[idx + 1].date)

							annotationsBetween = annotationsParsedDates.filter(_date => {
								return _date.isBetween(thisDate, nextDate)
							})
						} else {
							annotationsBetween = annotationsParsedDates.filter(_date => {
								return _date.isAfter(thisDate)
							})
						}
						if (annotationsBetween.length) {
							// '+ 1' for the initial _balance I was going to return
							return (new Array(annotationsBetween.length + 1).fill(_balance))
						}
						return _balance
					})

					return {
						label: capitalize(set.exchange),
						borderWidth: 2,
						fill: false,
						data: flatten(_data),
						borderColor: isDark
							? exchangeChartColors.dark[activityProp][lowEx]
							: exchangeChartColors.light[activityProp][lowEx],
						backgroundColor: isDark
							? exchangeChartColors.dark[activityProp][lowEx]
							: exchangeChartColors.light[activityProp][lowEx]
					}
				})
			}
		},
		options: {
			annotation: {
				events: ['mouseenter', 'mouseleave'],
				annotations
			},
			pan: {
				enabled: true,
				mode: 'x',
				speed: 20
			},
			zoom: {
				enabled: true,
				drag: {
					borderColor: isDark ? 'rgba(6,19,37,1)' : 'rgb(255,255,255,1)',
					borderWidth: 5,
					backgroundColor: isDark ? 'rgba(6,19,37,0.6)' : 'rgb(255,255,255,0.6)',
					animationDuration: 500
				},
				mode: 'xy',
				speed: 0.05
			},
			responsive: true,
			title: {
				display: true,
				text: 'Balances',
				fontSize: 20,
				fontStyle: 'bold',
				lineHeight: 2.5,
				fontColor: rgbaColor('#cccccc', 0.7)
			},
			legend: false,
			legendCallback: function(chart) {
				const exs = chart.config.data.datasets.map(d => d.label)

				let text = `<ul class="chart-legend">`

				for (const ex of exs) {
					const entry = props.config.companies.find(comp => {
						return comp.name.toLowerCase() === ex.toLowerCase()
					})
					const _activity = entry.active ? 'active' : 'inactive'
					const backgroundColor = isDark
						? exchangeChartColors.dark[_activity][ex.toLowerCase()]
						: exchangeChartColors.light[_activity][ex.toLowerCase()]

					text += `<li>
								<span class="${_activity}" style="background-color: ${backgroundColor}">${ex}</span>
							</li>`
				}
				text += '</ul>'

				return text
			},
			tooltips: {
				mode: 'index',
				displayColors: true,
				intersect: false,
				enabled: false,
				xPadding: 10,
				yPadding: 10,
				position: 'nearest',
				bodyFontColor: '#27bcfd',
				bodyFontStyle: 'italic',
				bodyFontSize: 13,
				bodySpacing: 4,
				titleFontSize: 15,
				titleFontColor: '#cccccc',
				backgroundColor: isDark ? 'rgba(6,19,37,0.9)' : 'rgba(255,255,255,0.9)',
				custom: function(tooltipModel) {
					let tooltipEl = document.getElementById('chartjs-tooltip')

					// Create element on first render
					if (!tooltipEl) {
						tooltipEl = document.createElement('div')
						tooltipEl.id = 'chartjs-tooltip'
						tooltipEl.innerHTML = '<table></table>'

						document.body.appendChild(tooltipEl)
					}
					// Hide if no tooltip
					if (tooltipModel.opacity === 0) {
						tooltipEl.style.opacity = 0

						return
					}
					// Set caret Position
					tooltipEl.classList.remove('above', 'below', 'no-transform')

					if (tooltipModel.yAlign) {
						tooltipEl.classList.add(tooltipModel.yAlign)
					} else {
						tooltipEl.classList.add('no-transform')
					}

					function getBody(bodyItem) {
						return bodyItem.lines
					}

					// Set Text
					if (tooltipModel.body) {
						const titleLines = tooltipModel.title || []
						const bodyLines = tooltipModel.body.map(getBody)

						let innerHtml = '<thead>'

						titleLines.forEach(function(title) {
							const fontStyle = tooltipModel._titleFontStyle || tooltipModel._bodyFontStyle || 'bold'
							const fontSize = tooltipModel.titleFontSize || tooltipModel.bodyFontSize || 12
							const fontColor = tooltipModel.titleFontColor || tooltipModel.bodyFontColor || '#9da9bb'
							const thStyle = `font-style: ${fontStyle}; font-size: ${fontSize}px; color: ${fontColor}`

							innerHtml += `<tr style="margin-bottom: ${tooltipModel.titleMarginBottom || 5}px">
											<th style="${thStyle}">${title}</th>
										</tr>`
						});
						innerHtml += '</thead><tbody>'

						bodyLines.forEach(function(body, i) {
							const colors = tooltipModel.labelColors[i]
							const spanStyle = `background-color: ${colors.backgroundColor}; border-color: ${colors.borderColor}; border-width: 2px; width: 10px; height: 10px; display: inline-block`
							const fontStyle = tooltipModel._bodyFontStyle || 'italic'
							const fontSize = tooltipModel.bodyFontSize || 10
							const fontColor = tooltipModel.bodyFontColor || '#000'
							const [ exchange, amount ] = body[0].split(':')
							const entry = props.config.companies.find(_entry => {
								return _entry.name.toLowerCase() === exchange.toLowerCase()
							})
							const _fontColor = (entry && entry.active) ? fontColor : rgbaColor(fontColor, 0.5)
							const tdStyle = `font-style: ${fontStyle}; font-size: ${fontSize}px; color: ${_fontColor}`
							const span = `<span style="${spanStyle}"></span>`
							const [ pounds, pence ] = amount.trim().split('.')

							innerHtml += `<tr style="margin-top: ${tooltipModel.bodySpacing || 2}px">
											<td style="${tdStyle}">${span} ${exchange.trim()}</td>
											<td style="${tdStyle}">£${pounds}.${(pence) ? pence.padEnd(2, '0') : '00'}</td>
										</tr>`
						});
						innerHtml += '</tbody>'

						const tableRoot = tooltipEl.querySelector('table')

						tableRoot.innerHTML = innerHtml;
					}
					// `this` will be the overall tooltip
					const position = this._chart.canvas.getBoundingClientRect()

					// Display, position, and set styles for font
					tooltipEl.style.opacity = 1
					tooltipEl.style.position = 'absolute'
					tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX - 230}px`
					tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY - 10}px`
					tooltipEl.style.fontFamily = '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
					tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`
					tooltipEl.style.pointerEvents = 'none'
					// Make sure when on the left of the chart, the tooltip overlays the side bar
					// So it's still visible
					tooltipEl.style.zIndex = 100000
					tooltipEl.style.backgroundColor = tooltipModel.backgroundColor || isDark
						? 'rgba(6,19,37,0.8)' : 'rgba(255,255,255,0.8)'
					tooltipEl.style.borderRadius = '1em'
				}
			},
			hover: {
				mode: 'index',
				intersect: true
			},
			scales: {
				xAxes: [
					{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Time',
							fontSize: 12,
							fontColor: rgbaColor('#cccccc', 0.7)
						},
						ticks: {
							callback: () => '',
							fontColor: rgbaColor('#cccccc', 0.7),
							fontStyle: 600
						}
					}
				],
				yAxes: [
					{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Balance (£)',
							fontSize: 14,
							fontColor: rgbaColor('#cccccc', 0.7)
						},
						ticks: {
							min: 0,
							fontColor: rgbaColor('#cccccc', 0.7),
							fontStyle: 600
						}
					}
				]
			},
			onClick: function(e) {
				setIsComparing(!isComparing)

				let comparingEl = document.getElementById('range-for-compare')

				const position = this.chart.canvas.getBoundingClientRect()
				const nearestEls = this.chart.chart.getElementsAtEventForMode(e, 'nearest', {
					intersect: false
				})

				if (nearestEls.length) {
					const nearest = nearestEls[0]

					if (!isComparing) {
						if (!comparingEl) {
							comparingEl = document.createElement('DIV')
							comparingEl.id = 'range-for-compare'
							comparingEl.addEventListener('click', destroyRange)
							comparingEl.addEventListener('mousemove', alterRange)
							comparingEl.style.opacity = 0.5
							comparingEl.style.backgroundColor = '#2c7be5'
							comparingEl.style.border = '2px blue solid'
							comparingEl.style.position = 'absolute'
							comparingEl.style.left = `${position.left + nearest._model.x + window.pageXOffset - 2.5}px`
							comparingEl.style.top = `${position.top + window.pageYOffset + this.chart.chart.scales['y-axis-0'].top}px`
							comparingEl.style.height = `${this.chart.chart.scales['y-axis-0'].height}px`
							comparingEl.style.width = '5px'

							document.body.appendChild(comparingEl)
						}
						const vals = this.chart.chart.data.datasets.map(d => {
							return {
								exchange: d.label,
								val: parseFloat(d.data[nearest._index])
							}
						})

						props.setRangeProfits({
							start: vals
						})
					}
				}

				if (isComparing && comparingEl) {
					comparingEl.remove()

					props.unsetRangeProfits()
				}
			},
			onHover: function(e) {
				let comparingEl = document.getElementById('range-for-compare')

				const position = this.chart.canvas.getBoundingClientRect()
				const nearestEls = this.chart.chart.getElementsAtEventForMode(e, 'nearest', {
					intersect: false
				})

				if (nearestEls.length) {
					const nearest = nearestEls[0]

					if (isComparing && comparingEl) {
						const _left = parseFloat(comparingEl.style.left.replace('px', ''))

						setCurrentEnd(position.left + nearest._model.x)
						comparingEl.style.width = `${position.left + (nearest._model.x - _left) + window.pageXOffset - 2.5}px`

						const vals = this.chart.chart.data.datasets.map(d => {
							return {
								exchange: d.label,
								val: parseFloat(d.data[nearest._index])
							}
						})

						props.setRangeProfits({
							end: vals
						})
					}
				}
			}
		}
	}
	const chartRef = useRef()

	const destroyRange = e => {
		const comparingEl = document.getElementById('range-for-compare')

		if (comparingEl) {
			comparingEl.remove()

			props.unsetRangeProfits()
		}
	}

	const alterRange = e => {
		// if (currentEnd) {
		// 	const comparingEl = document.getElementById('range-for-compare')
		// }
	}

	useEffect(() => {
		if (chartRef && chartRef.current) {
			_setLegend(htmlToReactParser.parse(chartRef.current.chartInstance.generateLegend()))
		}
	}, [chartRef])

	const updateBalances = async () => {
		setIsRefreshing(true)

		await Apis.Database.put('/api/config/balances/take', {
			addBalance: true
		})
		await Apis.Database.put('/api/config/activity/take', {
			types: ['deposits', 'withdrawals', 'commissions']
		})
		await props.fetchBalancesAndBets()

		setIsRefreshing(false)
	}

	const resetZoom = () => {
		chartRef.current.chartInstance.resetZoom()
	}

	Chart.pluginService.register({
		afterDraw: function(chart, easing) {
			if (chart.config.type === 'line') {
				if (chart.tooltip._active && chart.tooltip._active.length) {
					const activePoint = chart.controller.tooltip._active[0];
					const ctx = chart.ctx;
					const x = activePoint.tooltipPosition().x;
					const topY = chart.scales['y-axis-0'].top;
					const bottomY = chart.scales['y-axis-0'].bottom;

					ctx.save();
					ctx.beginPath();
					ctx.moveTo(x, topY);
					ctx.lineTo(x, bottomY);
					ctx.lineWidth = 1;
					ctx.strokeStyle = isDark ? '#27bcfd' : '#fff';
					ctx.stroke();
					ctx.restore();
				}
			}
		}
	})

	return !props.balances.balances.length ? (
		<Card className="text-center mb-3">
			<CardBody className="p-5">
				<div className="display-2 text-200">No Data</div>
				<p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">There are no balances to display.</p>
				<hr />
				<p>Please edit your time period search (or remove it altogether) to see data</p>
			</CardBody>
		</Card>
	) : (
		<Card className="mb-3">
			<CardBody className="rounded-soft bg-gradient">
				<Button onClick={resetZoom} style={{marginRight: 5}}>
					<FontAwesomeIcon
						icon="search-minus"/> Reset Zoom
				</Button>
				<Button onClick={updateBalances} style={{
					float: 'right',
					padding: '3px 8px 3px 8px'
				}}>
					<FontAwesomeIcon icon="sync-alt" className={isRefreshing ? 'fa-spin' : ''}/>
				</Button>
				<Line ref={chartRef} data={config.data} options={config.options} height={100} onMouseLeave={destroyRange}/>
				{ _legend }
			</CardBody>
		</Card>
	)
}

export default ProfitsLineChart
