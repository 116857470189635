import { connect } from 'react-redux'

import DashboardComponent from '../pages/Dashboard'
import { fetchedBalances,
	fetchedDashboardBets,
	fetchedActiveBets,
	fetchedEndedBets,
	fetchedConfig,
	fetchedActivity,
	setTimePeriod
} from '../redux/widgets'

const mapStateToProps = (state, props) => {
	return state
}

const mapDispatchToProps = dispatch => {
	return {
		fetchedBalances: balances => dispatch(fetchedBalances(balances)),
		fetchedDashboardBets: dashboardBets => dispatch(fetchedDashboardBets(dashboardBets)),
		fetchedActiveBets: activeBets => dispatch(fetchedActiveBets(activeBets)),
		fetchedEndedBets: endedBets => dispatch(fetchedEndedBets(endedBets)),
		fetchedConfig: config => dispatch(fetchedConfig(config)),
		fetchedActivity: activity => dispatch(fetchedActivity(activity)),
		setTimePeriod: timePeriod => dispatch(setTimePeriod(timePeriod))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardComponent)
