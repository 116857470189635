import React, { useContext, useEffect, useRef, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import AppContext from '../../context/Context'
import { rgbaColor, sportTypeChartColors, marketTypeFormat } from '../../helpers/utils'
import { Pie } from 'react-chartjs-2'
import NoData from '../utilities/NoData'
import { Parser as HtmlToReactParser } from 'html-to-react'

const htmlToReactParser = new HtmlToReactParser()

function getBetsPlacedOnSportTypes(bets) {
	return bets.reduce((acc, bet) => {
		const upEventType = bet.eventType.toUpperCase()

		acc[upEventType] = acc[upEventType] ? acc[upEventType] + 1 : 1

		return acc
	}, {})
}

const SportTypesCardPie = props => {
	const { isDark } = useContext(AppContext)
	const [ _legend, _setLegend ] = useState('')

	const config = {
		data(canvas) {
			const betsPlacedOnSportTypes = getBetsPlacedOnSportTypes(props.bets)

			return Object.keys(betsPlacedOnSportTypes).reduce(
				(acc, sport) => {
					acc.datasets[0].data.push(betsPlacedOnSportTypes[sport])
					acc.datasets[0].backgroundColor.push(isDark ? sportTypeChartColors.dark[sport.toLowerCase()] : sportTypeChartColors.light[sport.toLowerCase()])
					acc.datasets[0].borderWidth.push(1)
					acc.labels.push(marketTypeFormat(sport, ' '))

					return acc
				},
				{
					datasets: [
						{
							data: [],
							backgroundColor: [],
							borderWidth: []
						}
					],
					labels: []
				}
			)
		},
		options: {
			responsive: true,
			title: {
				display: true,
				text: '# of Sport Bets',
				fontSize: 18,
				fontStyle: 'bold',
				lineHeight: 2,
				fontColor: rgbaColor('#cccccc', 0.7)
			},
			legend: false,
			legendCallback: function(chart) {
				const sports = chart.config.data.labels

				let text = `<ul class="chart-legend">`

				for (const sport of sports) {
					const _sport = sport.replace(/\s/, '_').toLowerCase()
					const backgroundColor = isDark
						? sportTypeChartColors.dark[_sport]
						: sportTypeChartColors.light[_sport]

					text += `<li style="margin-bottom: 4px;">
								<span class="sub-chart" style="background-color: ${backgroundColor}">${sport}</span>
							</li>`
				}
				text += '</ul>'

				return text
			}
		}
	}
	const chartRef = useRef()

	useEffect(() => {
		if (chartRef && chartRef.current) {
			_setLegend(htmlToReactParser.parse(chartRef.current.chartInstance.generateLegend()))
		}
	}, [chartRef])

	return !props.bets.length ? (
		<NoData type="bets" canFilter={true}/>
	) : (
		<Card className="mb-3">
			<CardBody className="rounded-soft bg-gradient">
				<Pie ref={chartRef} data={config.data} options={config.options}/>
				{ _legend }
			</CardBody>
		</Card>
	)
}

export default SportTypesCardPie
