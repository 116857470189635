import React, { useState, useContext } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Table, Row } from 'reactstrap'
import AppContext from '../../context/Context'
import BetDetails from '../bets/BetDetails'
import { capitalize, getDisplayDate, marketTypeFormat } from '../../helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BetsModal = props => {
    const { isDark } = useContext(AppContext)
    const [ nestedModal, setNestedModal ] = useState({})

    const toggleNested = idx => {
        setNestedModal({
            ...nestedModal,
            [idx]: !nestedModal[idx]
        })
    }

    const getBetType = type => {
		if (!type) {
			return '-'
		}
		if (type.toUpperCase() === 'TRADEOUT') {
			return 'Trade Out'
		}
		return capitalize(type)
	}

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggleModal} size="xl">
            <ModalHeader toggle={props.toggleModal}>
                Bets
            </ModalHeader>
            <ModalBody>
                <Table isDark={isDark} responsive striped className="bet-table">
                    <thead>
                        <th>Type</th>
                        <th>Event Name</th>
                        <th>Event Type</th>
                        <th>Market Type</th>
                        <th>Placed</th>
                        <th></th>
                    </thead>
                    <tbody>
                        { props.bets && props.bets.map((bet, idx) => (
                            <>
                                <tr key={idx}>
                                    <td>{getBetType(bet.type)}</td>
                                    <td>{bet.eventName || '-'}</td>
                                    <td>{capitalize(bet.eventType || '-')}</td>
                                    <td>{marketTypeFormat(bet.generalMarketType || '-')}</td>
                                    <td>{getDisplayDate(bet.date || bet.completedDate)}</td>
                                    <td style={{color: "#27bcfd", fontSize: '1.5rem', cursor: 'pointer'}} onClick={() => toggleNested(idx)}>
                                        <FontAwesomeIcon
                                            icon="arrow-alt-circle-right"
                                            style={{verticalAlign: 'top'}}/>
                                    </td>
                                </tr>
                                <Modal isOpen={nestedModal[idx]} onClick={() => toggleNested(idx)} size="lg">
                                    <ModalHeader toggle={() => toggleNested(idx)}>
                                        Bet: <em>{bet._id}</em>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row className="bet-modal-row">
                                            <BetDetails bet={bet} idx={idx} size="sm"/>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={() => toggleNested(idx)}>OK</Button>
                                    </ModalFooter>
                                </Modal>
                            </>
                        )) }
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.toggleModal}>OK</Button>
            </ModalFooter>
        </Modal>
    )
}

export default BetsModal