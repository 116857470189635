import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import moment from 'moment'
import { CustomInput, FormText, Row, Col } from 'reactstrap'
import Apis from '../apis'
import CardSummary from '../components/dashboard/CardSummary'
import CardForm from '../components/dashboard/CardForm'
import ProfitsLineChart from '../components/dashboard/ProfitsLineChart'
import ExchangeBetsCardPie from '../components/dashboard/ExchangeBetsCardPie'
import SportTypesCardPie from '../components/dashboard/SportTypesCardPie'
import BetTypesCardPie from '../components/dashboard/BetsTypesCardPie'
import ExecutionTypesCardPie from '../components/dashboard/ExecutionTypesCardPie'
import ImmediateOrNotCardPie from '../components/dashboard/ImmediateOrNotCardPie'
import BetsModal from '../components/dashboard/BetsModal'
import Loader from '../components/common/Loader'
import ScreenSize from '../components/utilities/ScreenSize'
import { getAdvancedTotal } from '../helpers/utils'

const Dashboard = props => {
	// Usually the initial state would be 'null' but if you don't set anything in start or end & submit
	// The value is 0 to which when divided by 1000 is 'undefined'
	// 'undefined' !== 'null' so will do the search
	const [startDate, setStartDate] = useState(undefined)
	const [endDate, setEndDate] = useState(undefined)
	const [isLoading, setIsLoading] = useState(true)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [profits, setProfits] = useState()
	// const [percentageProfits, setPercentageProfits] = useState()
	const [showWithDepositsWithdrawals, setShowWithDepositsWithdrawals] = useState(false)
	// eslint-disable-next-line
	const [_config, _setConfig] = useState(props.config)

	async function fetchBalancesAndBets(_props = {}) {
		setIsLoading(true)

		const params = {
			startDate: _props._startDate || moment().subtract(1, 'days').toISOString(),
			endDate: _props._endDate || moment().toISOString(),
			limit: 100
		}
		if (!_props.render || (_props.render && !props.balances)) {
			const balancesResp = await Apis.Database.get('/api/config/balances', {
				params: {
					...params,
					info: ['balances', 'totalDifferences', 'exchangeDifferences']
				}
			})

			props.fetchedBalances(balancesResp.data)
		}
		if (!_props.render || (_props.render && !props.dashboardBets)) {
			const betsResp = await Apis.Database.get('/api/bets', {
				params: {
					...params,
					info: ['bets', 'active', 'ended']
				}
			})

			props.fetchedDashboardBets(betsResp.data.bets)

			if (betsResp.data.active) {
				props.fetchedActiveBets(betsResp.data.active)
			}
			if (betsResp.data.ended) {
				props.fetchedEndedBets(betsResp.data.ended)
			}
		}
		if (!props.config || !Object.keys(props.config).length) {
			const configResp = await Apis.Database.get('/api/config', {
				params: {
					types: ['general', 'arbitrage'],
					combine: true
				}
			})

			props.fetchedConfig(configResp.data)
			_setConfig(configResp.data)
		}
		// The default is the 1st log of the balances
		// So it's technically when I officially started I guess
		const activityResp = await Apis.Database.get('/api/config/activity', {
			params: {
				info: ['activities', 'exchangeCommissions', 'exchangeDeposits', 'exchangeWithdrawals'],
				startDate: params.startDate,
				endDate: params.endDate
			}
		})

		props.fetchedActivity(activityResp.data)

		setIsLoading(false)
	}

	useEffect(() => {
		fetchBalancesAndBets({
			render: true
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (props.balances && props.balances.totalDifference && props.activity) {
			setProfits({
				normalBasicTotal: props.balances.totalDifference || 0,
				normalAdvancedTotal: getAdvancedTotal(props.activity, props.balances.totalDifference)
			})
		}
	}, [props])

	const handleClick = e => {
		setIsModalOpen(true)
	}

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen)
	}

	const unsetRangeProfits = () => {
		setProfits({
			...profits,
			startRange: undefined,
			rangeBasicTotal: undefined,
			endRange: undefined
		})
	}

	const setRangeProfits = (_profits = {}) => {
		if (_profits.start) {
			setProfits({
				...profits,
				startRange: _profits.start,
				rangeBasicTotal: 0
			})
		}

		if (_profits.end && profits.startRange) {
			const startTotal = profits.startRange.reduce((acc, e) => {
				return acc + e.val
			}, 0)
			const endTotal = _profits.end.reduce((acc, e) => {
				return acc + e.val
			}, 0)

			setProfits({
				...profits,
				endRange: _profits.end,
				rangeBasicTotal: endTotal - startTotal
			})
		}
	}

	const handleSubmit = (_startDate, _endDate) => {
		let startDateToUse
		let endDateToUse

		// If you just submit the form again without altering the existing values there
		// Then the '_startDate' & '_endDate' will come back as unix timestamps not moment objects
		if (_startDate) {
			startDateToUse = (_startDate instanceof moment && _startDate.isValid())
				? _startDate.toISOString()
				: _startDate / 1000
		}
		if (_endDate) {
			endDateToUse = (_endDate instanceof moment && _endDate.isValid())
				? _endDate.toISOString()
				: _endDate / 1000
		}

		// I thought the above setting of state meant 'startDate' & 'endDate' would've been set immediately
		// & would be vals when into 'fetchBalancesAndBets' but it didn't so I pass them in as args
		if (endDateToUse !== endDate || startDateToUse !== startDate) {
			setEndDate(endDateToUse)
			setStartDate(startDateToUse)

			fetchBalancesAndBets({
				_startDate: startDateToUse,
				_endDate: endDateToUse
			})
		}
	}

	if (isLoading) {
		return <Loader/>
	}

	return (
		<>
			<div className="card-deck">
				<CardForm title="Period" color="info" handleSubmit={handleSubmit} startDate={startDate * 1000} endDate={endDate * 1000} />
				{ props.balances && props.activity && profits && (
					<CardSummary
						title="Profit"
						color="success"
						exchangeDifferences={props.balances.exchangeDifferences}
						config={props.config}>
						{ showWithDepositsWithdrawals ? (
							<CountUp end={profits.normalAdvancedTotal} duration={2} prefix="£" separator="," decimal="." decimals={2} />
						) : (
							<CountUp
								end={profits.rangeBasicTotal !== undefined ? profits.rangeBasicTotal : profits.normalBasicTotal}
								duration={2}
								prefix="£"
								separator=","
								decimal="."
								decimals={2}
								className={profits.rangeBasicTotal !== undefined ? 'text-info' : ''}/>
						)}
						<br/>
						<div style={{marginTop: 15}}>
							<CustomInput
								id="with-deposits-withdrawal"
								label="Include deposits &amp; withdrawals?"
								bsSize="lg"
								onChange={() => setShowWithDepositsWithdrawals(!showWithDepositsWithdrawals)}
								checked={showWithDepositsWithdrawals}
								type="switch"
							/>
						</div>
						<FormText color="muted" style={{fontSize: '25%'}}>
							Enabling the above toggle will include deposits &amp; withdrawals into the total profit.<br/>
							Withdrawals are added to the total while deposits are subtracted.<br/>
							The profit, at this point, is calculating your financial position overall &amp; not limited to the balances in exchange wallets.
						</FormText>
					</CardSummary>
				)}
				{ props.dashboardBets && (
					<>
						<CardSummary title="Bets Placed" color="info" linkText="Bets" handler={handleClick} activeBets={props.activeBets} endedBets={props.endedBets}>
							{props.dashboardBets.length}
						</CardSummary>
						<BetsModal isOpen={isModalOpen} toggleModal={toggleModal} bets={props.dashboardBets}/>
					</>
				) }
			</div>
			<div className="d-none d-md-block">
				{ props.balances && props.activity && props.config &&
					<ProfitsLineChart
						start={startDate * 1000}
						end={endDate * 1000}
						balances={props.balances}
						activity={props.activity}
						config={props.config}
						fetchBalancesAndBets={fetchBalancesAndBets}
						setRangeProfits={setRangeProfits}
						unsetRangeProfits={unsetRangeProfits}/>
				}
				<div className="card-deck">
					{ props.dashboardBets && props.config && (
						<>
							<Row>
								<Col lg={4} md={4} sm={4} xs={12}>
									<ExchangeBetsCardPie bets={props.dashboardBets} config={props.config}/>
								</Col>
								<Col lg={4} md={4} sm={4} xs={12}>
									<SportTypesCardPie bets={props.dashboardBets} />
								</Col>
								<Col lg={4} md={4} sm={4} xs={12}>
									<BetTypesCardPie bets={props.dashboardBets} />
								</Col>
								<Col lg={4} md={4} sm={4} xs={12}>
									<ExecutionTypesCardPie bets={props.dashboardBets}/>
								</Col>
								<Col lg={4} md={4} sm={4} xs={12}>
									<ImmediateOrNotCardPie bets={props.dashboardBets}/>
								</Col>
							</Row>
						</>
					) }
				</div>
			</div>
			<ScreenSize/>
		</>
	)
}

export default Dashboard
