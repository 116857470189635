import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import Main from './Main'
import './helpers/initFA'
import configureStore from './redux/store'

ReactDOM.render(
	<Main store={configureStore()}>
		<App />
	</Main>,
	document.getElementById('main')
)
