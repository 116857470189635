import React, { useContext } from 'react'
import { Navbar, NavbarToggler } from 'reactstrap'
import AppContext from '../../context/Context'
import Logo from './Logo'

const NavbarTop = () => {
	const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext)

	return (
		<Navbar light className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit" expand>
			<NavbarToggler onClick={() => setShowBurgerMenu(!showBurgerMenu)} id="burgerMenu" />
			<Logo at="navbar-top" width={40} id="topLogo" />
			{/* <Collapse navbar>
				<Nav navbar className="align-items-center d-none d-lg-block">
					<NavItem>
						<h3 style={{fontStyle: 'italic', fontWeight: 100}}>Bettrex Monitor</h3>
					</NavItem>
				</Nav>
			</Collapse> */}
		</Navbar>
	)
}

export default NavbarTop
