import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AppContext from './context/Context'
import { Provider } from 'react-redux'
import toggleStylesheet from './helpers/toggleStylesheet'
import { getItemFromStore, setItemToStore } from './helpers/utils'
import Apis from './apis'

const Main = props => {
	// const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', false));
	// const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', false));
	const [isDark, setIsDark] = useState(getItemFromStore('isDark', false))
	const [showBurgerMenu, setShowBurgerMenu] = useState(false)
	const [currency, setCurrency] = useState('$')
	const [version, setVersion] = useState('')

	const value = {
		isDark,
		setIsDark,
		showBurgerMenu,
		setShowBurgerMenu,
		currency,
		setCurrency,
		version,
		setVersion
	}

	const setStylesheetMode = mode => {
		setItemToStore(mode, value[mode])
		toggleStylesheet({ isDark })
	}

	useEffect(() => {
		async function fetchVersion() {
			const versionResp = await Apis.Logs.get('/api/version')

			setVersion(versionResp.data.version)
		}
		fetchVersion()
	}, [])

	useEffect(() => {
		setStylesheetMode('isDark')
		// eslint-disable-next-line
	}, [isDark])

	return (
		<Provider store={props.store}>
			<AppContext.Provider value={value}>{props.children}</AppContext.Provider>
		</Provider>
	)
}

Main.propTypes = {
	children: PropTypes.node
}

export default Main
