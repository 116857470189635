import React from 'react'
import { Card, CardBody } from 'reactstrap'

const ScreenSize = () => {
    return (
        <Card className="text-center mb-3 d-md-none d-lg-none">
            <CardBody className="p-5">
                <div className="display-2 text-200">Screen Size</div>
                <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">Your screen size is too small to display meaningful information. Please switch to a larger screen in order to view this</p>
                <hr />
            </CardBody>
        </Card>
    )
}

export default ScreenSize