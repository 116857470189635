import React, { useContext } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Table } from 'reactstrap'
import AppContext from '../context/Context'
import { getDisplayDate, capitalize } from '../helpers/utils'

const FailuresModal = props => {
    const { isDark } = useContext(AppContext)

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggleModal} size="xl">
            <ModalHeader toggle={props.toggleModal}>
                Failures
            </ModalHeader>
            <ModalBody>
                <Table isDark={isDark} borderless style={{tableLayout: 'fixed', width: '30%'}} className="failures-info-table">
                    <tbody>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Exchange</td>
                            <td>{capitalize((props.runner && props.runner.exchange) || '-')}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Bet ID</td>
                            <td>{(props.runner && props.runner.betId) || '-'}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Event ID</td>
                            <td>{(props.runner && props.runner.eventId) || '-'}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Market ID</td>
                            <td>{(props.runner && props.runner.marketId) || '-'}</td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>Runner ID</td>
                            <td>{(props.runner && props.runner.runnerId) || '-'}</td>
                        </tr>
                    </tbody>
                </Table>
                <Table isDark={isDark} responsive bordered className="failures-table" style={{tableLayout: 'fixed'}}>
                    <thead>
                        <tr>
                            <th style={{width: '10%'}}>Attempt</th>
                            <th style={{width: '12.5%'}}>Date</th>
                            <th style={{width: '25%'}}>Error</th>
                            <th style={{width: '52.5%'}}>Offer</th>
                        </tr>
                    </thead>
                    <tbody>
                        { props.runner && props.runner.failures && props.runner.failures.map((failure, idx) => (
                            <tr key={idx}>
                                <td>{failure.attempt}</td>
                                <td>{getDisplayDate(failure.date)}</td>
                                <td>{failure.error || failure.status}</td>
                                <td>
                                    <pre className="prettyprint text-muted" style={{border: 0, fontSize: 10}}>
                                        <code>
                                            {JSON.stringify(failure.offer, null, 4).replace(/\\n/g, '\\\n\\\t').replace(/\\/g, '')}
                                        </code>
                                    </pre>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.toggleModal}>OK</Button>
            </ModalFooter>
        </Modal>
    )
}

export default FailuresModal