import React from 'react'
import dayjs from 'dayjs'
import corner1 from '../assets/img/illustrations/corner-1.png'
import corner2 from '../assets/img/illustrations/corner-2.png'
import corner3 from '../assets/img/illustrations/corner-3.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// function getInactiveExchangeBalances(sorted, isFirstMissing) {
// 	const [ firstReport, lastReport ] = [ sorted[0], sorted[sorted.length - 1] ]

// 	if (isFirstMissing) {
// 		const exsInFirst = firstReport.balances.map(balance => balance.exchange)
// 		const exsNotPresent = lastReport.balances.filter(balance => {
// 			return !exsInFirst.includes(balance.exchange)
// 		}).map(balance => balance.exchange)
// 		const notActiveFirst = {}

// 		for (const ex of exsNotPresent) {
// 			for (const entry of sorted.reverse()) {
// 				const exsIn = entry.balances.map(balance => balance.exchange)

// 				if (exsIn.includes(ex)) {
// 					notActiveFirst[ex] = entry.balances.find(balance => {
// 						return balance.exchange === ex
// 					}).balance
// 				}
// 			}
// 		}
// 		return Object.keys(notActiveFirst).reduce((acc, ex) => {
// 			return acc + notActiveFirst[ex]
// 		}, 0)
// 	}
// 	const exsInLast = lastReport.balances.map(balance => balance.exchange)
// 	const exsNotPresent = firstReport.balances.filter(balance => {
// 		return !exsInLast.includes(balance.exchange)
// 	}).map(balance => balance.exchange)
// 	const notActiveLast = {}

// 	for (const ex of exsNotPresent) {
// 		for (const entry of sorted) {
// 			const exsIn = entry.balances.map(balance => balance.exchange)

// 			if (exsIn.includes(ex)) {
// 				notActiveLast[ex] = entry.balances.find(balance => {
// 					return balance.exchange === ex
// 				}).balance
// 			}
// 		}
// 	}
// 	return Object.keys(notActiveLast).reduce((acc, ex) => {
// 		return acc + notActiveLast[ex]
// 	}, 0)
// }

export const getAdvancedTotal = (activity, trueTotal) => {
	let advancedTotal = trueTotal

	if (activity.exchangeDeposits) {
		advancedTotal = Object.values(activity.exchangeDeposits).reduce((acc, val) => {
			return acc - val
		}, advancedTotal)
	}
	if (activity.exchangeWithdrawals) {
		advancedTotal = Object.values(activity.exchangeWithdrawals).reduce((acc, val) => {
			return acc + val
		}, advancedTotal)
	}
	return parseFloat(advancedTotal.toFixed(2))
	// return Object.keys(activity).reduce((_total, _ex) => {
	// 	if (_ex === 'betfair') {
	// 		const deposits = activity[_ex].reduce((_acc, s) => {
	// 			if (s.legacyData.marketName === 'DEPOSIT') {
	// 				_acc += s.amount
	// 			}
	// 			return _acc
	// 		}, 0)
	// 		const withdrawals = activity[_ex].reduce((_acc, s) => {
	// 			if (s.legacyData.marketName === 'WITHDRAWAL') {
	// 				// Withdrawals are negative so subtract them to add them...
	// 				_acc -= s.amount
	// 			}
	// 			return _acc
	// 		}, 0)

	// 		return _total - deposits + withdrawals
	// 	} else if (_ex === 'betdaq') {
	// 		const deposits = activity[_ex].reduce((_acc, s) => {
	// 			if (s.PostingCategory === 3 && s.Description.toUpperCase().includes('DEPOSIT:')) {
	// 				_acc += s.Amount
	// 			}
	// 			return _acc
	// 		}, 0)
	// 		const withdrawals = activity[_ex].reduce((_acc, s) => {
	// 			if (s.PostingCategory === 3 && s.Description.toUpperCase().includes('WITHDRAWAL:')) {
	// 				// Look above for why we subtract...
	// 				_acc -= s.Amount
	// 			}
	// 			return _acc
	// 		}, 0)

	// 		return _total - deposits + withdrawals
	// 	} else if (_ex === 'smarkets') {
	// 		const deposits = activity[_ex].reduce((_acc, s) => {
	// 			if (s.source === 'deposit') {
	// 				_acc += parseFloat(s.money_change)
	// 			}
	// 			return _acc
	// 		}, 0)
	// 		const withdrawals = activity[_ex].reduce((_acc, s) => {
	// 			if (s.source === 'withdraw') {
	// 				// Again...money is negative so subtract it
	// 				_acc -= parseFloat(s.money_change)
	// 			}
	// 			return _acc
	// 		}, 0)

	// 		return _total - deposits + withdrawals
	// 	}
	// 	return _total
	// }, trueTotal)
}

function getStartingArrCharIdx(message) {
	const arrStringIdx = message.indexOf('["')
	const arrArrIdx = message.indexOf('[[')
	const arrObjIdx = message.indexOf('[{')

	let arrNumIdx = message.search(/\[\d/)

	// If it's a 429 error message ('Too Many Requests'), in the message we have the limit rating for quick analysis
	// For example, "[52/50]" - this would match with usual 'arrNumIdx' so just ignore it
	if (message.includes('Too Many Requests') || message.includes('SAME EVENT')) {
		arrNumIdx = -1
	}
	const lowest = [arrStringIdx, arrObjIdx, arrNumIdx, arrArrIdx].sort()

	return lowest[0] >= 0 ? lowest[0] : lowest[1] >= 0 ? lowest[1] : lowest[2] >= 0 ? lowest[2] : lowest[3]
}

export const getNonApiLogs = logs => {
	if (logs && typeof logs === 'object' && Array.isArray(logs)) {
		return logs.filter(log => {
			return !log.message.includes('/api/logs')
				&& !log.message.includes('HTTP/')
				&& !log.message.includes('USE: LOGS_HTTP_API')
		})
	}
	return []
}

export const getTotalNumberOfUnmatchedMarkets = (markets, eventId) => {
	if (markets && markets[eventId] && Object.keys(markets[eventId])) {
		return Object.keys(markets[eventId]).reduce((acc, ex) => {
			return acc + markets[eventId][ex].length
		}, 0)
	}
	return 0
}

export const isIterableArray = array => Array.isArray(array) && !!array.length

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) => JSON.parse(store.getItem(key)) || defaultValue
export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, JSON.stringify(payload))

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
	let hex
	hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue)
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b))
	return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

export const getOutcome = bet => {
	const isBackBack = bet.subType === 'BackBack'
	const winningRunners = bet.runners.filter(runner => runner.outcome === 'WIN')
	const lostRunners = bet.runners.filter(runner => runner.outcome === 'LOSE')
	const voidRunners = bet.runners.filter(runner => runner.outcome === 'VOID')
	const matchedRunners = bet.runners.filter(runner => runner.status === 'MATCHED')
	const partiallyMatchedRunners = bet.runners.filter(runner => runner.status === 'PARTIALLY_MATCHED')

	if (winningRunners.length) {
		const totalWin = winningRunners.reduce((total, runner) => {
			if (isBackBack) {
				return total + bet.netProfits[`outcome${runner.outcomeSide}`]
			}
			return total + bet.netProfits[runner.side.toLowerCase()]
		}, 0)

		if (parseFloat(totalWin) >= 0) {
			if (matchedRunners.length !== bet.runners.length) {
				return (
					<td className="outcome-net net-positive-unmatched">{`+${totalWin.toFixed(2)}`}</td>
				)
			}
			return (
				<td className="outcome-net net-positive">{`+${totalWin.toFixed(2)}`}</td>
			)
		}
		if (matchedRunners.length !== bet.runners.length || partiallyMatchedRunners.length) {
			return (
				<td className="outcome-net net-negative-unmatched">{totalWin.toFixed(2)}</td>
			)
		}
		return (
			<td className="outcome-net net-negative">{totalWin.toFixed(2)}</td>
		)
	}

	// This would occur if bettrex mistakenly bet on 2 different markets thinking it was the same one
	// & both bets come in as a loss
	if (lostRunners.length === bet.runners.length) {
		const totalLoss = lostRunners.reduce((total, runner) => {
			const loss = runner.side === 'back' ? runner.stake : (runner.price - 1) * runner.stake

			return total - loss
		}, 0)

		return (
			<td className="outcome-net net-negative">{totalLoss.toFixed(2)}</td>
		)
	}

	// An event can end & 1 side of the bet can still not be matched
	if (matchedRunners.length && (matchedRunners.length !== bet.runners.length)) {
		if (matchedRunners[0].outcome) {
			const loss = matchedRunners[0].side === 'back'
				? matchedRunners[0].stake.toFixed(2)
				: ((matchedRunners[0].price - 1) * matchedRunners[0].stake).toFixed(2)

			return (
				<td className="outcome-net net-negative-unmatched">{`-${loss}`}</td>
			)
		}
	}
	if (voidRunners.length && voidRunners.length === bet.runners.length) {
		return (
			<td className="outcome-net net-void">+0.00</td>
		)
	}
	return (
		<td className="outcome-net net-nothing">-</td>
	)
}

export const getSetConfigRequestBody = params => {
	return {
		arbitrage: {
			sportsToUse: params.sportsToUse,
			maxPercentToUse: parseFloat(params.maxPercentToUse) / 100,
			maxQuantityToUse: parseFloat(params.maxQuantityToUse) / 100,
			startLossAllowed: parseFloat(params.startLossAllowed) / 100,
			maxLossAllowed: parseFloat(params.maxLossAllowed) / 100,
			marketBackBackLimit: parseInt(params.marketBackBackLimit),
			marketBackLayLimit: parseFloat(params.marketBackLayLimit) / 100,
			amBetting: params.amBetting,
			minBalanceToBet: parseFloat(params.minimumBalanceToBet),
			attemptNumToAllowLoss: parseInt(params.attemptNumToAllowLoss || 20),
			safeTimeLimit: parseFloat(params.safeTimeLimit) / 100,
			unmatchedLimit: parseFloat(params.unmatchedLimit),
			defaultPoll: parseInt(params.defaultPoll),
			exposureLimit: parseFloat(params.exposureLimit),
			differenceToProfitSplit: params.differenceToProfitSplit,
			executionType: params.executionType
		},
		general: {
			companies: params.exchanges.map(ex => {
				return {
					name: ex.toUpperCase(),
					bettable: params.bettable[ex.toLowerCase()],
					active: params.active[ex.toLowerCase()],
					...(params.notActiveReason[ex.toLowerCase()] && {
						notActiveReason: params.notActiveReason[ex.toLowerCase()]
					})
				}
			}),
			amountToSave: params.amountToSave,
		}
	}

}

export const isActive = (config, ex) => {
	if (config && config.companies) {
		const _ex = config.companies.find(comp => {
			return comp.name.toUpperCase() === ex.toUpperCase()
		})

		return _ex && _ex.active
	}
}

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`

export const exchanges = ['BETFAIR', 'SMARKETS', 'MATCHBOOK', 'BETDAQ']

export const getInitialExchangeStates = (exchanges, config) => {
	return exchanges.reduce((acc, ex) => {
		if (config && config.companies && config.companies.length) {
			const found = config.companies.find(comp => comp.name === ex)

			acc.bettable[ex.toLowerCase()] = (found && found.bettable) || false
			acc.active[ex.toLowerCase()] = (found && found.active) || false
			acc.notActiveReason[ex.toLowerCase()] = (found && found.notActiveReason) || ''
		}
		return acc
	}, {
		bettable: {},
		active: {},
		notActiveReason: {}
	})
}

export const sportTypes = [
	'SOCCER',
	'TENNIS',
	'BASKETBALL',
	'RUGBY UNION',
	'RUGBY LEAGUE',
	'HORSE RACING',
	'GOLF',
	'CRICKET',
	'BASEBALL',
	'AMERICAN FOOTBALL',
	'SNOOKER',
	'BOXING',
	'CYCLING',
	'ESPORTS'
]

export const getLogLevelIcon = level => {
	if (!level) {
		return (
			<span style={{color: 'rgb(39, 188, 253)'}}>
				<FontAwesomeIcon icon="info-circle" style={{verticalAlign: 'middle'}}/>
			</span>
		)
	}
	switch (level) {
		case 'warn':
			return (
				<span style={{color: 'orange'}}>
					<FontAwesomeIcon icon="exclamation-triangle" style={{verticalAlign: 'middle'}}/>
				</span>
			)
		case 'error':
			return (
				<span style={{color: 'red'}}>
					<FontAwesomeIcon icon="exclamation-circle" style={{verticalAlign: 'middle'}}/>
				</span>
			)
		case 'debug':
			return (
				<span style={{color: 'rgb(0, 128, 0)'}}>
					<FontAwesomeIcon icon="bug" style={{verticalAlign: 'middle'}}/>
				</span>
			)
		case 'verbose':
			return (
				<span style={{color: 'rgb(128, 0, 128)'}}>
					<FontAwesomeIcon icon="comment" style={{verticalAlign: 'middle'}}/>
				</span>
			)
		case 'silly':
			return (
				<span style={{color: 'rgb(255, 20, 147)'}}>
					<FontAwesomeIcon icon="heart" style={{verticalAlign: 'middle'}}/>
				</span>
			)
		default:
			return (
				<span style={{color: 'rgb(39, 188, 253)'}}>
					<FontAwesomeIcon icon="info-circle" style={{verticalAlign: 'middle'}}/>
				</span>
			)
	}
}

export const getSimpleLogMessage = message => {
	if (!message) {
		return '-'
	}
	const startArrCharIdx = getStartingArrCharIdx(message)
	const startObjCharIdx = message.indexOf('{"')

	// Doesn't contain an array at all that we care about
	// 0 would be falsey but can be the start idx
	if (startArrCharIdx < 0) {
		if (startObjCharIdx < 0) {
			return message
		}
		const beforeJSON = message.substr(0, startObjCharIdx)

		return `${beforeJSON} {...}`
	}
	// Only an array is present
	if (startObjCharIdx < 0 || startArrCharIdx < startObjCharIdx) {
		const beforeJSON = message.substr(0, startArrCharIdx)

		return `${beforeJSON} [...]`
	}
	const beforeJSON = message.substr(0, startObjCharIdx)

	return `${beforeJSON} {...}`
}

export const getDetailedLogMessage = message => {
	try {
		if (!message) {
			return '-'
		}
		const startArrCharIdx = getStartingArrCharIdx(message)
		// Below is how an obj MUST start
		// This will also be the FIRST occurence of an object
		const startObjCharIdx = message.indexOf('{"')

		// Doesn't contain an array at all that we care about
		// 0 would be falsey but can be the start idx
		if (startArrCharIdx < 0) {
			if (startObjCharIdx < 0) {
				return message
			}
			// Assuming here that there is only going to be 1 JSON obj per message
			// For the time being, this is a safe assumption I think :)
			const endObjCharIdx = message.lastIndexOf('}')
			const objParsed = JSON.parse(message.substring(startObjCharIdx, endObjCharIdx + 1))

			return JSON.stringify(objParsed, null, 4).replace(/\\n/g, '\\\n\\\t').replace(/\\/g, '')
		}
		// Only an array is present
		if (startObjCharIdx < 0 || startArrCharIdx < startObjCharIdx) {
			// Again...assuming here that there is only going to be 1 JSON arr per message
			const endArrCharIdx = message.lastIndexOf(']')
			const arrParsed = JSON.parse(message.substring(startArrCharIdx, endArrCharIdx + 1))

			return JSON.stringify(arrParsed, null, 4).replace(/\\n/g, '\\\n\\\t').replace(/\\/g, '')

		}
		// Assuming here that there is only going to be 1 JSON obj per message
		// For the time being, this is a safe assumption I think :)
		const _endObjCharIdx = message.lastIndexOf('}')
		const _objParsed = JSON.parse(message.substring(startObjCharIdx, _endObjCharIdx + 1))

		return JSON.stringify(_objParsed, null, 4).replace(/\\n/g, '\\\n\\\t').replace(/\\/g, '')
 	} catch(err) {
		throw err
	}
}

export const colors = ['#2c7be5', '#00d97e', '#e63757', '#39afd1', '#fd7e14', '#02a8b5', '#727cf5', '#6b5eae', '#ff679b', '#f6c343']

export const themeColors = {
	primary: '#2c7be5',
	secondary: '#748194',
	success: '#00d27a',
	info: '#27bcfd',
	warning: '#f5803e',
	danger: '#e63757',
	light: '#f9fafd',
	dark: '#0b1727'
}

// export const grays = {
// 	white: '#fff',
// 	100: '#f9fafd',
// 	200: '#edf2f9',
// 	300: '#d8e2ef',
// 	400: '#b6c1d2',
// 	500: '#9da9bb',
// 	600: '#748194',
// 	700: '#5e6e82',
// 	800: '#4d5969',
// 	900: '#344050',
// 	1000: '#232e3c',
// 	1100: '#0b1727',
// 	black: '#000'
// }

// export const darkGrays = {
// 	white: '#fff',
// 	1100: '#f9fafd',
// 	1000: '#edf2f9',
// 	900: '#d8e2ef',
// 	800: '#b6c1d2',
// 	700: '#9da9bb',
// 	600: '#748194',
// 	500: '#5e6e82',
// 	400: '#4d5969',
// 	300: '#344050',
// 	200: '#232e3c',
// 	100: '#0b1727',
// 	black: '#000'
// }

export const sportTypeChartColors = {
	light: {
		// Red
		soccer: 'rgba(254, 155, 175, 0.8)',
		// Orange
		tennis: 'rgba(255, 206, 146, 0.8)',
		// Yellow
		basketball: 'rgba(247, 255, 42, 0.8)',
		// Grass Green
		rugby_union: 'rgba(65, 238, 56, 0.8)',
		// Light Blue
		rugby_league: 'rgba(114, 198, 255, 0.8)',
		// Jade Green
		horse_racing: 'rgba(14, 255, 177, 0.8)',
		// Grey
		golf: 'rgba(208, 210, 212, 0.8)',
		// Cyan
		cricket: 'rgba(118, 240, 240, 0.8)',
		// Pink
		baseball: 'rgba(243, 158, 243, 0.8)',
		// Purple
		american_football: 'rgba(187, 127, 255, 0.8)',
		// Pink/Red
		snooker: 'rgba(255, 117, 176, 0.8)'
	},
	dark: {
		// Red
		soccer: 'rgba(255, 5, 55, 0.8)',
		// Orange
		tennis: 'rgba(239, 131, 0, 0.8)',
		// Yellow
		basketball: 'rgba(219, 227, 0, 0.8)',
		// Grass Green
		rugby_union: 'rgba(38, 200, 30, 0.8)',
		// Light Blue
		rugby_league: 'rgba(0, 137, 229, 0.8)',
		// Jade Green
		horse_racing: 'rgba(0, 209, 142, 0.8)',
		// Grey
		golf: 'rgba(178, 180, 184, 0.8)',
		// Cyan
		cricket: 'rgba(23, 211, 211, 0.8)',
		// Pink
		baseball: 'rgba(233, 62, 233, 0.8)',
		// Purple
		american_football: 'rgba(119, 0, 255, 0.8)',
		// Pink/Red
		snooker: 'rgba(234, 0, 101, 0.8)',
		// Maroon
		boxing: 'rgba(165, 42, 42, 0.8)'
	}
}

export const executionTypeChartColours = {
	light: {
		// Pink/Red
		keep: 'rgba(255, 117, 176, 0.8)',
		// Cyan
		immediate: 'rgba(118, 240, 240, 0.8)'
	},
	dark: {
		// Pink/Red
		keep: 'rgba(234, 0, 101, 0.8)',
		// Cyan
		immediate: 'rgba(23, 211, 211, 0.8)'
	}
}

export const immediateOrNotChartColours = {
	light: {
		// Orange
		yes: 'rgba(255, 206, 146, 0.8)',
		// Purple
		no: 'rgba(187, 127, 255, 0.8)'
	},
	dark: {
		// Orange
		yes: 'rgba(239, 131, 0, 0.8)',
		// Purple
		no: 'rgba(119, 0, 255, 0.8)'
	}
}

export const betTypeChartColors = {
	light: {
		// Grass Green
		arb: 'rgba(65, 238, 56, 0.8)',
		// Pink/Red
		scalp: 'rgba(255, 117, 176, 0.8)',
		// Cyan
		tradeout: 'rgba(118, 240, 240, 0.8)'
	},
	dark: {
		// Grass Green
		arb: 'rgba(38, 200, 30, 0.8)',
		// Pink/Red
		scalp: 'rgba(234, 0, 101, 0.8)',
		// Cyan
		tradeout: 'rgba(23, 211, 211, 0.8)'
	}
}

export const getDisplayProfits = profits => {
	if (!profits) {
		return '-'
	}
	if (profits && typeof profits === 'object' && Object.keys(profits).length) {
		return Object.values(profits).reduce((acc, profit) => {
			return acc + profit
		}, 0).toFixed(2)
	}
	return '-'
}

export const getDisplayDate = date => {
	if (!date) {
		return <p>-</p>
	}
	const parsed = dayjs(date)

	if (parsed.isValid()) {
		return <p>{parsed.format('DD/MM/YYYY HH:mm:ss')}</p>
	}
	return <p>{date}</p>
}

export const sort = (toSort, type, isAsc) => {
	let defaultSort = toSort

	switch (type) {
		case 'TYPE':
			defaultSort = toSort.sort((a, b) => {
				return a.type - b.type
			})
			break
		case 'NET_PROFITS':
			defaultSort = toSort.sort((a, b) => {
				const combinedNetA = Object.values(a.netProfits).reduce((acc, profit) => {
					return acc + profit
				}, 0)
				const combinedNetB = Object.values(b.netProfits).reduce((acc, profit) => {
					return acc + profit
				}, 0)

				return combinedNetB - combinedNetA
			})
			break
		case 'GROSS_PROFITS':
			defaultSort = toSort.sort((a, b) => {
				const combinedGrossA = Object.values(a.grossProfits).reduce((acc, profit) => {
					return acc + profit
				}, 0)
				const combinedGrossB = Object.values(b.grossProfits).reduce((acc, profit) => {
					return acc + profit
				}, 0)

				return combinedGrossB - combinedGrossA
			})
			break
		case 'PLACE_TIME':
			defaultSort = toSort.sort((a, b) => {
				const aDate = a.dateAdded || a.completedDate || a.incompletedDate
				const bDate = b.dateAdded || b.completedDate || b.incompletedDate

				if (aDate && bDate) {
					return new Date(bDate) - new Date(aDate)
				}
				return b._id - a._id
			})
			break
		case 'IMMEDIATE':
			defaultSort = toSort.sort((a, b) => {
				return b.immediate - a.immediate
			})
			break
		case 'TIMESTAMP':
			defaultSort = toSort.sort((a, b) => {
				return new Date(a.timestamp) - new Date(b.timestamp)
			})
			break
		case 'START_TIME':
			defaultSort = toSort.sort((a, b) => {
				return new Date(b.startTime) - new Date(a.startTime)
			})
			break
		case 'STATUS':
			defaultSort = toSort.sort((a, b) => {
				if (a.status === b.status) {
					return 1
				}
				if (['LIVE', 'ENDED'].includes(a.status)) {
					return (a.status === 'LIVE') ? -1 : 1
				}
				if (a.status === 'UPCOMING') {
					return (b.status === 'LIVE') ? 1 : -1
				}
				return (a.status < b.status) ? -1 : 1
			})
			break
		default:
			defaultSort = toSort
	}
	return (isAsc) ? [...defaultSort] : [...defaultSort.reverse()]
}

export const getDisplayAmount = (amount, isPrice) => {
	if (amount === undefined) {
		return '-'
	}
	const _amount = amount.toString()
	const [ int, decimal ] = [ _amount.split('.')[0], _amount.split('.')[1] || '00' ]
	const prefix = isPrice ? '' : '£'

	return `${prefix}${parseFloat(`${int}.${decimal.padEnd(2, '0')}`).toFixed(2)}`
}

export const BetNamePropPair = {
	_id: 'ID',
	type: 'Type',
	subType: 'Sub Type',
	_eventId: 'Event ID',
	_marketId: 'Market ID',
	eventType: 'Event Type',
	eventName: 'Event Name',
	eventShortName: 'Event Short Name',
	generalMarketType: 'Market Type',
	country: 'Country',
	startTime: 'Start Time',
	immediate: 'Immediate?',
	completedDate: 'Completed Time',
	incompletedDate: 'Incompleted Time',
	result: 'Message',
	executionType: 'Execution Type',
	netProfits: 'Net Profits',
	grossProfits: 'Gross Profits',
}

export const getImage = color => {
	switch (color) {
		case 'warning':
			return corner1
		case 'info':
			return corner2
		case 'success':
			return corner3
		default:
			return corner1
	}
}

export const exchangeChartColors = {
	light: {
		active: {
			// Red
			matchbook: 'rgba(254, 155, 175, 0.9)',
			// Orange
			betfair: 'rgba(255, 206, 146, 0.9)',
			// Grass Green
			smarkets: 'rgba(46, 178, 39, 0.9)',
			// Purple
			betdaq: 'rgba(187, 127, 255, 0.9)'
		},
		inactive: {
			// Red
			matchbook: 'rgba(254, 155, 175, 0.2)',
			// Orange
			betfair: 'rgba(255, 206, 146, 0.2)',
			// Grass Green
			smarkets: 'rgba(46, 178, 39, 0.2)',
			// Purple
			betdaq: 'rgba(187, 127, 255, 0.2)'
		}
	},
	dark: {
		active: {
			// Red
			matchbook: 'rgba(255, 5, 55, 0.9)',
			// Orange
			betfair: 'rgba(239, 131, 0, 0.9)',
			// Grass Green
			smarkets: 'rgba(38, 200, 30, 0.9)',
			// Purple
			betdaq: 'rgba(119, 0, 255, 0.9)'
		},
		inactive: {
			// Red
			matchbook: 'rgba(255, 5, 55, 0.2)',
			// Orange
			betfair: 'rgba(239, 131, 0, 0.2)',
			// Grass Green
			smarkets: 'rgba(38, 200, 30, 0.2)',
			// Purple
			betdaq: 'rgba(119, 0, 255, 0.2)'
		}
	}
}

export const chartColors = {
	light: {
		red: 'rgba(255, 99, 132, 0.8)',
		orange: 'rgba(255, 159, 64, 0.8)',
		yellow: 'rgba(255, 205, 86, 0.8)',
		green: 'rgba(65, 238, 56, 0.8)',
		blue: 'rgba(54, 162, 235, 0.8)',
		purple: 'rgba(140, 85, 251, 0.8)',
		grey: 'rgba(201, 203, 207, 0.8)'
	},
	dark: {
		red: 'rgba(255, 45, 87, 0.8)',
		orange: 'rgba(234, 113, 0, 0.8)',
		yellow: 'rgba(239, 167, 0, 0.8)',
		green: 'rgba(25, 202, 16, 0.8)',
		blue: 'rgba(54, 162, 235, 0.8)',
		purple: 'rgba(115, 45, 255, 0.8)',
		grey: 'rgba(201, 203, 207, 0.8)'
	}
}

export const rgbColors = colors.map(color => rgbColor(color))
export const rgbaColors = colors.map(color => rgbaColor(color))

export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.substring(1).toLowerCase()}`

export const marketTypeFormat = (str, joiner) => {
	return str.split('_').map(s => {
		return `${s.charAt(0).toUpperCase()}${s.substring(1).toLowerCase()}`
	}).join(joiner || '_')
}
