import React, { useContext } from 'react'
import { Col, Row } from 'reactstrap'
import AppContext from '../../context/Context'

const Footer = () => {
	const { version } = useContext(AppContext)

	return (
		<footer>
			<Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
				<Col sm="auto">
					<p className="mb-0 text-600">
						Thank you for creating with Falcon <span className="d-none d-sm-inline-block">| </span>
						<br className="d-sm-none" /> {new Date().getFullYear()} &copy; <a href="https://themewagon.com">Themewagon</a>
					</p>
				</Col>
				<Col sm="auto">
					<p className="mb-0 text-600">v{version}</p>
				</Col>
			</Row>
		</footer>
	)
}

export default Footer
