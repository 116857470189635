import React from 'react'
import { Card, CardBody } from 'reactstrap'
import PropTypes from 'prop-types'
import { capitalize } from '../../helpers/utils'

const NoData = props => {
    return (
        <Card className="text-center mb-3 d-none d-md-block">
            <CardBody className="p-5">
                <div className="display-2 text-200">No {capitalize(props.type)}</div>
                <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">There are no {props.type} to display.</p>
                <hr />
                { props.canFilter && (
                    <p>Please edit your time period search (or remove it altogether) to see {props.type}</p>
                ) }
            </CardBody>
        </Card>
    )
}

NoData.propTypes = {
    type: PropTypes.string.isRequired,
    canFilter: PropTypes.bool
}

export default NoData