import React, { useContext } from 'react'
import { Card, CardBody } from 'reactstrap'
import AppContext from '../../context/Context'
import { rgbaColor, capitalize, betTypeChartColors } from '../../helpers/utils'
import { Pie } from 'react-chartjs-2'
import NoData from '../utilities/NoData'

function getTypesOfBetsPlaced(bets) {
	return bets.reduce((acc, bet) => {
		const upType = bet.type.toUpperCase()

		acc[upType] = acc[upType] ? (acc[upType] + 1) : 1

		return acc
	}, {})
}

const BetTypesCardPie = props => {
	const { isDark } = useContext(AppContext)

	const config = {
		data(canvas) {
			const typesOfBetsPlaced = getTypesOfBetsPlaced(props.bets)

			return Object.keys(typesOfBetsPlaced).reduce(
				(acc, type) => {
					acc.datasets[0].data.push(typesOfBetsPlaced[type])
					acc.datasets[0].backgroundColor.push(isDark ? betTypeChartColors.dark[type.toLowerCase()] : betTypeChartColors.light[type.toLowerCase()])
					acc.datasets[0].borderWidth.push(1)
					acc.labels.push(capitalize(type))

					return acc
				},
				{
					datasets: [
						{
							data: [],
							backgroundColor: [],
							borderWidth: []
						}
					],
					labels: []
				}
			)
		},
		options: {
			responsive: true,
			title: {
				display: true,
				text: '# of Bets on Type',
				fontSize: 18,
				fontStyle: 'bold',
				lineHeight: 2.5,
				fontColor: rgbaColor('#cccccc', 0.7)
			},
			legend: {
				labels: {
					fontSize: 12,
					fontStyle: 'italic',
					fontColor: rgbaColor('#cccccc', 0.7)
				},
				display: true,
				position: 'right'
			}
		}
	}

	return !props.bets.length ? (
		<NoData type="bets" canFilter={true}/>
	) : (
		<Card className="mb-3">
			<CardBody className="rounded-soft bg-gradient">
				<Pie data={config.data} options={config.options} />
			</CardBody>
		</Card>
	)
}

export default BetTypesCardPie
